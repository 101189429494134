import React, { useEffect, useState, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import { setLeadField } from "../../redux/actions";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";

import { AUTO_COMPLETE_LOCATION } from "../../data/copy";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  actionIcon: {},
  endAdornment: {
    top: "50%",
    transform: "translateY(-50%)", // This will center it vertically
    position: "absolute",
    right: "0", // Adjust this as needed
  },
  clearIndicator: {
    "& span": {
      "& svg": {
        "& path": {
          d: "path('M20.3125 5.6875L5.6875 20.3125')",
        },
      },
    },
  },
}));

const DropDownIcon = (props) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.125 9.75L13 17.875L4.875 9.75"
      stroke="#AAAFB4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const CrossIcon = (props) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.3125 5.6875L5.6875 20.3125"
      stroke="#00628C"
      // strokeLinecap="round"
      // strokeLinejoin="round"
    />
    <path
      d="M20.3125 20.3125L5.6875 5.6875"
      stroke="#00628C"
      // strokeLinecap="round"
      // strokeLinejoin="round"
    />
  </svg>
);

const AutoCompleteLocation = (props) => {
  const { address } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState(address || null);
  const [inputValue, setInputValue] = useState(address || "");
  const [options, setOptions] = useState([]);
  const loaded = useRef(false);

  const { lang } = useSelector((state) => state.reducer);
  const { fromDOM } = useSelector((state) => state.reducer.lead.fields.address);
  const copy = AUTO_COMPLETE_LOCATION[lang];

  if (
    typeof window !== "undefined" &&
    !loaded.current &&
    process.env.REACT_APP_MODE !== "build"
  ) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyAmFVxkjuJggwqq2Sqz3915bK5fXAdl9cI&libraries=places", // only for dev
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(
          {
            ...request,
            componentRestrictions: { country: process.env.REACT_APP_ORIGIN },
          },
          callback
        );
      }, 200),
    []
  );

  useEffect(() => {
    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (fromDOM && value) {
      setInputValue(value);
      dispatch(setLeadField("address", value, false));
    }

    if (inputValue === "") {
      setOptions([]);
      return;
    }

    fetch({ input: inputValue }, (value) =>
      value ? setOptions(value) : setOptions([])
    );
  }, [value, inputValue, fetch]);

  useEffect(() => {
    if (props.address !== value) {
      setValue(props.address);
    }
  }, [props.address, value]);

  const { error, helperText, width } = props;

  return (
    <Grid item xs={width.xs} sm={width.sm}>
      <Autocomplete
        id="new-valuation-address"
        closeIcon={<CrossIcon className={classes.actionIcon}></CrossIcon>}
        popupIcon={<DropDownIcon className={classes.actionIcon}></DropDownIcon>}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.description
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={inputValue}
        classes={{
          endAdornment: classes.endAdornment,
        }}
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          if (props.onChange) {
            props.onChange(newValue ? newValue.description : null);
          }
        }}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        noOptionsText={copy.noOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            label={copy.label}
            variant="outlined"
            fullWidth
            error={error}
            helperText={helperText}
            required
          />
        )}
        renderOption={(option) => {
          if (!option.structured_formatting) return;
          const matches =
            option.structured_formatting.main_text_matched_substrings;
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length])
          );

          return (
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                  >
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
      />
    </Grid>
  );
};

export default AutoCompleteLocation;
