import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import PageMovementButton from "../PageMovementButton";

import { ERROR_PAGE } from "../../data/copy";
import { alfredoAPIRequester } from "../../exporters";
import { uploadToAnalytics } from "../../helpers/Analytics";
import { setLeadID, setError, setPrediction } from "../../redux/actions";

const Buttons = (props) => {
  const { page, setPage, loading, setLoading, handleEntryChange, mobile } =
    props;
  const { lang, lead, client, realtor, form, postalCode } = useSelector(
    (state) => state.reducer
  );
  const { entries, buttons } = form[page];

  const errorCopy = ERROR_PAGE[lang];

  const dispatch = useDispatch();

  const submitSMSRequest = () => {
    const { token } = JSON.parse(localStorage.getItem("auth"));
    const payload = {
      lang,
      fields: {},
      characteristics: [...lead.characteristics],
      client: {},
    };

    Object.entries(lead.fields).forEach(
      ([key, field]) => (payload.fields[key] = field.value ? field.value : null)
    );
    Object.entries(client).forEach(
      ([key, clientDetail]) => (payload.client[key] = clientDetail.value)
    );

    payload.client.email = payload.client.email2;

    const queryParams = window.location.search;
    const urlParams = new URLSearchParams(queryParams);

    if (payload.client.areyou === "sell") payload.client.type = "sell";
    else payload.client.type = payload.client.project || payload.client.areyou;

    if (payload.client.type === "other") payload.client.type = "visit";

    payload.client.name = `${payload.client.firstName} ${payload.client.lastName}`;

    payload.client["utm_id"] = urlParams.get("utm_id");
    payload.client["utm_source"] = urlParams.get("utm_source");
    payload.client["utm_medium"] = urlParams.get("utm_medium");
    payload.client["utm_campaign"] = urlParams.get("utm_campaign");
    payload.client["utm_term"] = urlParams.get("utm_term");
    payload.client["utm_content"] = urlParams.get("utm_content");

    if (realtor) payload.realtor = realtor;

    setLoading(true);
    alfredoAPIRequester
      .post({
        urlPath: "leads",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: payload,
      })
      .then((response) => {
        dispatch(setLeadID(response.id));

        const numberOfPages = form.length;
        if (page < numberOfPages - 1) {
          setPage(page + 1);
        }

        uploadToAnalytics(window.dataLayer, {
          predict: response.predict,
          leadID: response.id,
          clientEmail: client.email2.value,
          clientType: payload.client.type,
          realtor,
          origin: process.env.REACT_APP_ORIGIN,
          postalCode,
        });

        dispatch(
          setPrediction({ predict: response.predict, agent: response.agent })
        );
      })
      .catch((error) => {
        let errorToupload = "error";
        if (error?.data?.detail === "maxLeadsReached")
          errorToupload = "maxLeadsReached";

        dispatch(setError(errorToupload));
        uploadToAnalytics(window.dataLayer, {
          error: { id: errorToupload, message: errorCopy[errorToupload] },
          realtor,
          origin: process.env.REACT_APP_ORIGIN,
          postalCode,
        });
      })
      .finally(() => setLoading(false));
  };

  const renderButtons = (button) => {
    if (
      ["next", "previous", "submit", "validate", "start"].includes(button.type)
    ) {
      return (
        <PageMovementButton
          key={button.type}
          page={page}
          mobile={mobile}
          width={button.width}
          buttonType={button.type}
          handleClick={!loading && handlePageMovement}
        />
      );
    }
  };

  const validateEntries = () => {
    const errors = entries.map((entry) => {
      if (entry.id === "characteristics") return false;
      if (entry.id === "address" && lead.fields.address.error) return true;

      const object =
        entry.id === "address"
          ? {
              value: {
                address: lead.fields.address.value,
                latitude: lead.fields.latitude.value,
                longitude: lead.fields.longitude.value,
              },
            }
          : lead.fields[entry.id] || client[entry.id] || lead[entry.id];

      if (
        entry.conditional &&
        client[entry.conditional.key].value !== entry.conditional.value
      )
        return false;

      return handleEntryChange(entry.id, object.value, entry.validate);
    });

    return errors.every((err) => err === false);
  };

  const handlePageMovement = (buttonType) => {
    // Iterate through every field to check if they are
    // valid for submission (or to go to the next page)
    let advance = true;
    const numberOfPages = form.length;

    if (["next", "submit", "start"].includes(buttonType))
      advance = validateEntries();

    if (!advance) return;

    switch (buttonType) {
      case "next":
      case "start":
        if (page < numberOfPages - 1) {
          setPage(page + 1);
        }
        return;
      case "previous":
        if (page > 0) {
          setPage(page - 1);
        }
        return;
      case "submit":
        submitSMSRequest();
        return;
      default:
        return;
    }
  };

  return (
    <Grid
      container
      justify={page === 0 ? "center" : "space-between"}
      alignItems="center"
    >
      {buttons && buttons.map(renderButtons)}
    </Grid>
  );
};

export default Buttons;
