import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, CircularProgress, useMediaQuery, Typography } from "@material-ui/core";
import { setKeyValues } from "../../redux/actions";
import { useTheme } from "@material-ui/core/styles";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import FormSelectField from "../FormSelectField";
import FormTextField from "../FormTextField";
import FormChipSelectField from "../FormChipSelectField";
import FormCharacteristic from "../FormCharacteristic";
import AutoCompleteWrapper from "../AutoCompleteWrapper";
import SMSValidator from "../SMSValidator";
import PredictResults from "../PredictResults";
import styled from "styled-components";

import { FORM_FIELDS, PAGE_TITLES, MAIN_SUBTITLES } from "../../data/copy";

const Form = (props) => {
  const dispatch = useDispatch();
  const { page, setPage, loading, setLoading, handleEntryChange } = props;
  const { lang, lead, client, metadata, form } = useSelector((state) => state.reducer);

  const { title, entries } = form[page];

  const copy = FORM_FIELDS[lang];
  const copyPage = PAGE_TITLES[lang];

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  const postalCodeRegexPT = /^\d{4}-\d{3}$/;

  const validateAddress = (geocodedAddress, type, origin) => {
    let valid = false;

    geocodedAddress.address_components.forEach((component) => {
      if (component.types.includes(type)) {
        if (
          type === "postal_code" &&
          postalCodeRegexPT.test(component.long_name) &&
          origin === "pt"
        )
          valid = true;
        else if (
          type === "postal_code" &&
          !postalCodeRegexPT.test(component.long_name) &&
          origin === "pt"
        )
          valid = false;
        else valid = true;

        if (valid && type === "postal_code")
          dispatch(setKeyValues({ postalCode: component.long_name }));
      }
    });

    return valid;
  };

  const renderEntry = (entry, idx) => {
    if (entry.conditional && client[entry.conditional.key].value !== entry.conditional.value)
      return <div key={entry.id}></div>;

    switch (entry.type) {
      case "select":
        return (
          <FormSelectField
            key={entry.id}
            idx={idx}
            entry={entry}
            handleEntryChange={handleEntryChange}
            $mobile={mobile}
          />
        );
      case "list":
        return metadata.characteristics.map((option, index) => (
          <FormCharacteristic
            key={`${option}-${lead.characteristics.includes(option)}`}
            option={option}
            label={copy.options.characteristics[option]}
            entry={entry}
            handleEntryChange={handleEntryChange}
          />
        ));
      case "chipSelect":
        return (
          <Grid
            item
            xs={entry?.width?.xs || 12}
            sm={entry?.width?.sm || 6}
            id={entry.id}
            key={entry.id}
            style={{ paddingBottom: "10px" }}
          >
            <FormChipSelectField
              key={entry.id}
              mobile={mobile}
              id={entry.id}
              error={lead.fields[entry.id]?.error || client[entry.id]?.error}
              helperText={lead.fields[entry.id]?.helperText || client[entry.id]?.helperText}
              options={metadata[entry.id]}
              labels={copy.options[entry.id]}
              value={lead.fields[entry.id] ? lead.fields[entry.id].value : client[entry.id].value}
              validate={entry.validate}
              handleEntryChange={handleEntryChange}
              title={entry.required ? `${copy.labels[entry.id]}*` : copy.labels[entry.id]}
            ></FormChipSelectField>
          </Grid>
        );
      case "autocomplete":
        return (
          <AutoCompleteWrapper
            key={entry.id}
            entry={entry}
            mobile={mobile}
            width={entry.width}
            address={lead.fields[entry.id].value}
            error={lead.fields[entry.id].error}
            helperText={lead.fields[entry.id].helperText}
            onChange={(address) => {
              let addressToUse;
              let validPostalCode;
              let validStreetNumber;

              if (!address) {
                handleEntryChange(entry.id, address, entry.validate);
                return;
              }
              return geocodeByAddress(address)
                .then((results) => {
                  validPostalCode = validateAddress(
                    results[0],
                    "postal_code",
                    process.env.REACT_APP_ORIGIN
                  );
                  validStreetNumber = validateAddress(
                    results[0],
                    "street_number",
                    process.env.REACT_APP_ORIGIN
                  );

                  addressToUse = results[0].formatted_address;

                  return getLatLng(results[0]);
                })
                .then((latLng) => {
                  handleEntryChange(
                    entry.id,
                    {
                      address: addressToUse,
                      latitude: latLng.lat,
                      longitude: latLng.lng,
                      validPostalCode,
                      validStreetNumber,
                    },
                    entry.validate
                  );
                });
            }}
          />
        );
      case "sms":
        return (
          <SMSValidator
            key={entry.id}
            width={entry.width}
            page={page}
            setPage={setPage}
            loading={loading}
            setLoading={setLoading}
          />
        );
      case "predict":
        return <PredictResults key={entry.id} width={entry.width} />;
      case "hidden":
        return null;
      default:
        return (
          <FormTextField
            key={entry.id}
            idx={idx}
            $mobile={mobile}
            entry={entry}
            handleEntryChange={handleEntryChange}
            suppressTitle={title === "estimateReady"}
          />
        );
    }
  };

  const renderBody = () => {
    return (
      <Grid item xs={12} key="body">
        <Grid container>
          <Grid item xs={12}>
            <Grid container style={{ padding: "0px 20px" }}>
              {(form[page].subTitle === "selectExtraCharacteristics" ||
                form[page].subTitle === "insertPersonalDetails") && (
                <Title item xs={12}>
                  <Typography variant="subtitle1" style={{ fontSize: mobile ? "16px" : "18px" }}>
                    {MAIN_SUBTITLES[lang][form[page].subTitle]}
                  </Typography>
                </Title>
              )}
              {entries.map(renderEntry)}
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ padding: "10px 20px 0" }}>
            <Typography variant="caption">{page === 5 && renderPrivacyPolicy()}</Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderPrivacyPolicy = () => {
    const [text, privacyPolicy] = copy.labels.privacyPolicy.split("<a>");
    return (
      <>
        {copy.labels.mandatoryField}
        <br></br>
        <br></br>
        {text.replaceAll(
          "{IAD_ORIGIN}",
          process.env.REACT_APP_ORIGIN === "pt" ? "iad Portugal" : "iad España"
        )}{" "}
        <a
          href={
            process.env.REACT_APP_ORIGIN === "pt"
              ? "https://www.iadportugal.pt/politica-de-privacidade"
              : "https://www.iadespana.es/en/politica-de-privacidad"
          }
          target="_blank"
          rel="noreferrer"
        >
          {privacyPolicy}
        </a>
      </>
    );
  };

  const renderLoading = () => {
    return (
      <Loading item xs={12} key="loading">
        <Grid container justify="center">
          <LoadingWheelContainer item>
            <CircularProgress></CircularProgress>
          </LoadingWheelContainer>
          <LoadingMessage item xs={12}>
            <Typography variant="h4" color="primary">
              <div style={{ padding: "10px" }}>{copyPage.loading}</div>
            </Typography>
          </LoadingMessage>
        </Grid>
      </Loading>
    );
  };

  if (page >= form.length) return;

  return (
    <Grid container alignItems="center" justify="center">
      {loading ? renderLoading() : renderBody()}
    </Grid>
  );
};

const LoadingWheelContainer = styled(Grid)`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
`;

const Loading = styled(Grid)`
  padding: 10px;
`;

const LoadingMessage = styled(Grid)`
  padding: 10px 0;
`;

const Title = styled(Grid)`
  padding-bottom: 5px;
`;

export default Form;
