import axios from "axios";

export const submitToSlack = async (message) => {
  const webhookUrl =
    "https://hooks.slack.com/services/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";

  const data = {
    text: JSON.stringify(message),
  };

  await axios.post(webhookUrl, JSON.stringify(data), {
    withCredentials: false,
    transformRequest: [
      (data, headers) => {
        delete headers.post["Content-Type"];
        return data;
      },
    ],
  });
};

if (typeof window !== "undefined") {
  window.onerror = function (message, source, lineno, colno, error) {
    const auth = JSON.parse(localStorage.getItem("auth"));

    submitToSlack({
      name: auth?.email,
      error: JSON.stringify(error.stack),
      location: window.location.pathname,
    });
  };
}
