const metadata = {
  addType: ["sell", "rent"],
  assetType: ["apartment", "house"],
  buildingYear: {
    max: 2025,
    min: 1700,
  },
  characteristics: [
    "doubleWindows",
    "suite",
    "terrace",
    "garden",
    "balcony",
    "pool",
    "airConditioning",
    "closedGarage",
    "equippedKitchen",
    "elevator",
    "centralHeating",
    "parking",
  ],
  condition: ["new", "used", "renovated", "inConstruction", "forRecovery"],
  energyEfficiency: ["A", "B", "C", "D", "E", "F", "G"],
  grossArea: {
    max: 2000,
    min: 11,
  },
  latitude: {
    max: 43.77288055419922,
    min: 27.64048194885254,
  },
  longitude: {
    max: 4.305311679840088,
    min: -18.098941802978516,
  },
  numberOfParkingSpots: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  numberOfRooms: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  numberOfWcs: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  owner: [true, false],
  project: ["sell", "buy", "other"],
  timeframe: ["ongoing", "3months", "3to6months", "6monthsOrMore", "estimate"],
  areyou: ["buy", "professional", "other"],
  // type: ["buy", "sell", "professional", "visit"],
  sellProcess: ["start", "ongoing", "estimate"],
  alreadyTriedToSell: [false, true],
  listedInAgency: [false, true],
};

export default metadata;
