import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import ReactCodeInput from "react-code-input";
import { Grid, Typography } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

import { PALLETE_PRIMARY_COLOR } from "../../theme/colors";
import { alfredoAPIRequester } from "../../exporters";
import { setKeyValues } from "../../redux/actions";
import { SMS_VALIDATION } from "../../data/copy";

import { uploadToAnalytics } from "../../helpers/Analytics";

const SMSValidator = (props) => {
  const { token } = JSON.parse(localStorage.getItem("auth"));

  const dispatch = useDispatch();
  const { width, page, setPage } = props;
  const [error, setError] = useState(null);
  const [SMSCode, setSMSCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState(1);

  const { lang, leadID, client, realtor, form } = useSelector(
    (state) => state.reducer
  );
  const copy = SMS_VALIDATION[lang];

  const handleSMSChange = (value) => {
    setSMSCode(value);
    if (value.length === 4) {
      if (!error) validateSMSRequest(value);
      else if (error === "invalidSMSCode") validateSMSRequest(value);
    }
  };

  const validateSMSRequest = (SMSCode) => {
    setLoading(true);
    alfredoAPIRequester
      .post({
        urlPath: "leads/validate_sms",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          code: SMSCode,
          id: leadID,
        },
      })
      .then((response) => {
        if (response.predict) {
          dispatch(setKeyValues(response));
        }

        uploadToAnalytics(window.dataLayer, {
          predict: response.predict,
          leadID,
          clientEmail: client.email.value,
          realtor,
        });

        const numberOfPages = form.length;
        if (page < numberOfPages - 1) {
          setPage(page + 1);
        }
      })
      .catch((error) => {
        let _error;
        if (!error?.data?.detail) _error = "error";
        else _error = error.data.detail;

        setError(_error);

        // only if the user gets the code wrong we want to clean
        // the form so we can input the code again
        if (_error === "invalidSMSCode") {
          setSMSCode("");
          // this will make the component re-render and reset
          // all values stored in the input elements
          setKey(key + 1);
        }

        uploadToAnalytics(window.dataLayer, {
          error: { id: _error, message: copy[_error] || copy.error },
          realtor,
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <FormField item xs={width.xs} sm={width.sm}>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <Typography variant="h5">{copy.smsValidation}</Typography>
        </Grid>

        <PaddedInput item>
          <SMSCodeInput
            key={key}
            type="number"
            fields={4}
            value={SMSCode}
            onChange={handleSMSChange}
          />
        </PaddedInput>
        <Grid item xs={12} style={{ paddingBottom: 20 }}>
          <Grid container justify="center">
            {error && <ErrorText item>{copy[error] || copy.error}</ErrorText>}
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            <TextIcon style={{ paddingRight: 10 }} />
            {copy.description}
          </Typography>
        </Grid>
      </Grid>
    </FormField>
  );
};

const PaddedInput = styled(Grid)`
  padding: 20px 0px 10px 0px;
`;

const SMSCodeInput = styled(ReactCodeInput)`
  input {
    border-radius: 10px;
    border: 1px solid;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    margin: 4px;
    padding-left: 8px;
    width: 36px;
    height: 42px;
    font-size: 32px;
    box-sizing: border-box;
    color: ${PALLETE_PRIMARY_COLOR};
    background-color: white;
    border-color: lightgrey;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

const FormField = styled(Grid)`
  padding: 5px 10px;
`;

const TextIcon = styled(InfoIcon)`
  vertical-align: middle;
`;

const ErrorText = styled(Grid)`
  color: red;
  font-size: 12px;
`;

export default SMSValidator;
