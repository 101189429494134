import React from "react";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import AutoCompleteLocation from "../AutoCompleteLocation";
import styled from "styled-components";

import { FORM_FIELDS } from "../../data/copy";

const AutocompleteWrapper = (props) => {
  const { entry, mobile, ...rest } = props;
  const { lang } = useSelector((state) => state.reducer);
  const copy = FORM_FIELDS[lang];

  return (
    <FormField item xs={entry.width.xs} sm={entry.width.sm}>
      <Grid container>
        <Title item xs={12}>
          <Typography
            variant="subtitle1"
            style={{ fontSize: mobile ? "16px" : "18px" }}
          >
            {entry.required
              ? `${copy.labels[entry.id]}*`
              : copy.labels[entry.id]}
          </Typography>
        </Title>
        <Grid item xs={12}>
          <AutoCompleteLocation {...rest}></AutoCompleteLocation>
        </Grid>
      </Grid>
    </FormField>
  );
};

const FormField = styled(Grid)`
  width: 100%;
`;

const Title = styled(Grid)`
  padding-bottom: 5px;
`;

export default AutocompleteWrapper;
