import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Grid, Typography, Chip, Button } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import StarIcon from "@material-ui/icons/Star";
import StarOutlineIcon from "@material-ui/icons/StarOutline";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PhoneIcon from "@material-ui/icons/Phone";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

import {
  PALLETE_PRIMARY_COLOR,
  PALLETE_LIGHT_PRIMARY_COLOR,
  PALLETE_LIGHT_SECONDARY_COLOR,
} from "../../theme/colors";
import { PREDICTION } from "../../data/copy";

const PredictResults = (props) => {
  const { width } = props;
  const { fields } = useSelector((state) => state.reducer.lead);
  const { lang, predict, agent } = useSelector((state) => state.reducer);

  const mobile = window.innerWidth < 500;

  const copy = PREDICTION[lang];

  const thousandMillionParser = (price) =>
    lang === "fr"
      ? parseInt(price / 1e6)
        ? `${(price / 1e6).toFixed(2)} M€`
        : `${(price / 1e3).toFixed(0)} k€`
      : parseInt(price / 1e6)
      ? `${(price / 1e6).toFixed(2)}M€`
      : `${(price / 1e3).toFixed(0)}k€`;

  const predictsRounded = {
    min: {
      gross: Math.round(predict.min.gross / 1000) * 1000,
      perSq: parseInt((Math.round(predict.min.gross / 1000) * 1000) / fields.grossArea.value),
    },
    avg: {
      gross: Math.round(predict.avg.gross / 1000) * 1000,
      perSq: parseInt((Math.round(predict.avg.gross / 1000) * 1000) / fields.grossArea.value),
    },
    max: {
      gross: Math.round(predict.max.gross / 1000) * 1000,
      perSq: parseInt((Math.round(predict.max.gross / 1000) * 1000) / fields.grossArea.value),
    },
  };

  const renderPredictLabelsMobile = (key) => {
    const splittedWords = copy[key].split(" ");
    const lastWord = splittedWords.pop();
    const mappings = [splittedWords.join(" "), lastWord];

    return mappings.map((word, idx) => (
      <Label item idx={idx} xs={12}>
        <Typography variant="caption" key={word}>
          {word}
        </Typography>
      </Label>
    ));
  };

  const renderPriceInterval = () => {
    return (
      <Grid item xs={12} key="priceInterval">
        <PredictionIntervalBox container justify="space-between">
          {["min", "avg", "max"].map((key, idx) => (
            <Grid item key={key} style={{ width: "105px" }}>
              <Grid
                container
                style={{
                  textAlign: idx === 0 ? "left" : idx === 1 ? "center" : "right",
                }}
                justify={idx === 0 ? "flex-start" : idx === 1 ? "center" : "flex-end"}
              >
                {mobile ? (
                  renderPredictLabelsMobile(key)
                ) : (
                  <Label item xs={12}>
                    <Typography variant="caption">{copy[key]}</Typography>
                  </Label>
                )}
                <PaddedChip item type={key} xs={12}>
                  <Grid container justify="center">
                    <Grid item>
                      {predictsRounded[key].gross.toLocaleString("en").replace(/,/g, " ")} €
                    </Grid>
                    <Grid item style={{ fontSize: "10px" }}>
                      {predictsRounded[key].perSq.toLocaleString("en").replace(/,/g, " ")} €/m²
                    </Grid>
                  </Grid>
                </PaddedChip>
              </Grid>
            </Grid>
          ))}
          <PriceGradient item xs={12}>
            <Grid container justify="center">
              <Grid item>
                <svg
                  style={{
                    width: 20,
                    height: 15,
                  }}
                >
                  <polygon
                    points="0,12 10,0 20,12"
                    style={{
                      fill: PALLETE_LIGHT_SECONDARY_COLOR,
                    }}
                  />
                </svg>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <GradientBar />
            </Grid>
          </PriceGradient>
        </PredictionIntervalBox>
      </Grid>
    );
  };

  const renderConfidenceLevel = () => {
    const qualityInter = Math.abs(predict.max.gross - predict.min.gross) / predict.max.gross;

    let starsFilled;
    if (qualityInter < 0.07) starsFilled = 5;
    else if (qualityInter < 0.15) starsFilled = 4;
    else if (qualityInter < 0.25) starsFilled = 3;
    else starsFilled = 2;

    const stars = [...Array(starsFilled).fill(true), ...Array(5 - starsFilled).fill(false)];

    return (
      <ConfidenceLevelContainer key="confidence" item xs={12}>
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="body1" color="primary">
              {copy.confidenceLevel}
            </Typography>
          </Grid>
          <StarGrid item xs>
            {stars.map((filled, idx) =>
              filled ? (
                <StarIcon key={idx} color="primary"></StarIcon>
              ) : (
                <StarOutlineIcon key={idx} color="primary"></StarOutlineIcon>
              )
            )}
          </StarGrid>
        </Grid>
      </ConfidenceLevelContainer>
    );
  };

  const renderAgentInfo = () => {
    return (
      <AgentInfoBody item xs={12} key="agentInfo">
        <Grid container>
          <AgentContainer item xs={12}>
            <Agent variant="h3">{copy.agent}</Agent>
          </AgentContainer>
          <AgentContainer item xs={12}>
            <Grid container alignItems="center" justify="center">
              <Grid item>
                <AgentImage alt={agent.name} src={agent.image}></AgentImage>
              </Grid>
              <Grid item xs={mobile ? 12 : true}>
                <Grid container justify={mobile ? "center" : "flex-start"}>
                  <Grid item>
                    <Agent variant="h3">{agent.name}</Agent>
                  </Grid>
                  <AgentInfoContainer item xs={12}>
                    <Grid container alignItems="center" justify={mobile ? "center" : "flex-start"}>
                      <AgentLocationIcon></AgentLocationIcon>
                      <AgentInfo variant="caption">{agent.location}</AgentInfo>
                    </Grid>
                  </AgentInfoContainer>
                  {/* {!mobile ? ( */}
                  <>
                    {" "}
                    <AgentInfoContainer item xs={12}>
                      <Grid
                        container
                        alignItems="center"
                        justify={mobile ? "center" : "flex-start"}
                      >
                        <AgentEmailIcon></AgentEmailIcon>
                        <AgentInfo variant="caption">{agent.email}</AgentInfo>
                      </Grid>
                    </AgentInfoContainer>
                    <AgentInfoContainer item xs={12}>
                      <Grid
                        container
                        alignItems="center"
                        justify={mobile ? "center" : "flex-start"}
                      >
                        <AgentPhoneIcon></AgentPhoneIcon>
                        <AgentInfo variant="caption">{agent.phone}</AgentInfo>
                      </Grid>
                    </AgentInfoContainer>
                  </>
                  {/* ) : (
                    <>
                      <Grid item>
                        <Link href={`tel:+${agent.phone}`}>
                          <CallButton>{copy.call}</CallButton>
                        </Link>
                      </Grid>
                      <Grid item>
                        <Link href={`mailto:${agent.email}`}>
                          <SendEmailButton>{copy.sendEmail}</SendEmailButton>
                        </Link>
                      </Grid>
                    </>
                  )} */}
                </Grid>
              </Grid>
            </Grid>
          </AgentContainer>
        </Grid>
      </AgentInfoBody>
    );
  };

  return (
    <PaddedItem item xs={width.xs} sm={width.sm}>
      <Grid container>
        {[renderPriceInterval(), renderConfidenceLevel()]}
        <Typography variant="caption">{copy.disclaimer}</Typography>
        {agent && renderAgentInfo()}
      </Grid>
    </PaddedItem>
  );
};

const Link = styled.a`
  text-decoration: none;
`;

const CallButton = styled(Button)`
  width: 135px;
  margin: 10px;
  border-radius: 10px;
  color: #ffffff;
  background-color: #e87722;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    background-color: #e87722;
  }
`;

const SendEmailButton = styled(Button)`
  width: 135px;
  margin: 10px;
  border-radius: 10px;
  color: #ffffff;
  background-color: #186e93;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    background-color: #186e93;
  }
`;

const AgentInfoBody = styled(Grid)`
  padding-top: 15px;
`;

const AgentInfoContainer = styled(Grid)`
  margin: 5px 0;
`;

const AgentImage = styled(Avatar)`
  height: 150px;
  width: 150px;
  margin-right: 20px;
`;

const AgentPhoneIcon = styled(PhoneIcon)`
  color: #aaafb4;
  padding-right: 5px;
`;

const AgentEmailIcon = styled(MailOutlineIcon)`
  color: #aaafb4;
  padding-right: 5px;
`;

const AgentLocationIcon = styled(LocationOnIcon)`
  color: #aaafb4;
  padding-right: 5px;
`;

const AgentInfo = styled(Typography)`
  font-size: 15px;
`;

const AgentContainer = styled(Grid)`
  margin: 10px 0;
`;

const Agent = styled(Typography)`
  font-size: 1.375em;
  padding: 10px 0;
`;

const ConfidenceLevelContainer = styled(Grid)`
  padding: 20px 0;
`;

const StarGrid = styled(Grid)`
  margin-left: 15px;
`;

const Label = styled(Grid)`
  // width: 60px;
  padding-bottom: ${(props) => (props.idx === 0 ? "0px" : "8px")};
`;

const PredictionIntervalBox = styled(Grid)`
  border: 1px solid #cfcfcf;
  border-radius: 18px;
  padding: 27px;
`;

const GradientBar = styled.hr`
  width: 100%;
  height: 10px;
  margin: 4px;
  border: 0;
  border-radius: 10px;

  background-image: linear-gradient(
    to left,
    ${PALLETE_PRIMARY_COLOR},
    ${PALLETE_LIGHT_PRIMARY_COLOR}
  );
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
`;

const PaddedChip = styled(Grid)`
  padding: 5px;
  border-radius: 5px;
  ${(props) =>
    props.type === "avg"
      ? "color: #e87722; background-color: #fdf2ea;"
      : "color: #00628c; background-color: #ebf4ff;"}

  .MuiChip-label {
    padding-left: 6px;
    padding-right: 6px;
  }
`;

const PriceGradient = styled(Grid)`
  padding-top: 8px;
`;

const PaddedItem = styled(Grid)``;

export default PredictResults;
