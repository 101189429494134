import {
  SET_LEAD_FIELD,
  SET_LEAD_CLIENT_DETAIL,
  TOGGLE_LEAD_LIST_ELEMENT,
  TOGGLE_ERROR_LEAD_FIELD,
  SET_LANGUAGE,
  TOGGLE_ERROR_LEAD_CLIENT_DETAIL,
  SET_PREDICTION,
  SET_LEAD_ID,
  SET_ERROR,
  SET_KEY_VALUES,
  SET_AGENT_REFERENCE,
} from "./constants";

export const setLeadClientDetail = (key, value) => ({
  type: SET_LEAD_CLIENT_DETAIL,
  key,
  value,
});

export const setAgentReference = (value) => ({
  type: SET_AGENT_REFERENCE,
  value,
});

export const setLeadField = (key, value, fromDOM = false) => ({
  type: SET_LEAD_FIELD,
  key,
  value,
  fromDOM,
});

export const toggleLeadListElement = (key, element) => ({
  type: TOGGLE_LEAD_LIST_ELEMENT,
  key,
  element,
});

export const toggleErrorLeadField = (key, error, helperText) => ({
  type: TOGGLE_ERROR_LEAD_FIELD,
  key,
  error,
  helperText,
});

export const toggleErrorLeadClientDetail = (key, error, helperText) => ({
  type: TOGGLE_ERROR_LEAD_CLIENT_DETAIL,
  key,
  error,
  helperText,
});

export const setLanguage = (lang) => ({
  type: SET_LANGUAGE,
  lang,
});

export const setPrediction = (value) => ({
  type: SET_PREDICTION,
  value,
});

export const setLeadID = (value) => ({
  type: SET_LEAD_ID,
  value,
});

export const setError = (error) => ({
  type: SET_ERROR,
  error,
});

export const setKeyValues = (payload) => ({
  type: SET_KEY_VALUES,
  payload,
});
