import { FORM_ERRORS } from "../data/copy";

const validateField = (
  value,
  type,
  required,
  lang = "pt",
  fieldMetadata = null
) => {
  const copy = FORM_ERRORS[lang];

  const isRequired = required && checkRequired(copy, value);
  if (isRequired && isRequired.error) return isRequired;

  if (value) {
    // If the allowed values are in list form or in object form
    if (fieldMetadata) {
      if (fieldMetadata instanceof Array) {
        return validateList(copy, fieldMetadata, type, value);
      } else if (fieldMetadata instanceof Object) {
        if (type === "integer") {
          return checkInteger(
            copy,
            value,
            fieldMetadata.min,
            fieldMetadata.max
          );
        } else if (type === "address") {
          return checkAddress(copy, value, fieldMetadata);
        } else {
          return checkFloat(copy, value, fieldMetadata.min, fieldMetadata.max);
        }
      }
    } else {
      switch (type) {
        case "email":
          return validateEmail(copy, value);
        case "phone":
          return validatePhone(copy, value);
        case "postalCode":
          return validatePostCode(copy, value);
        default:
          return validateText(value);
      }
    }

    return validateText(value);
  }

  return { value, error: false, helperText: " " };
};

const checkAddress = (
  copy,
  {
    address,
    latitude,
    longitude,
    validPostalCode = true,
    validStreetNumber = true,
  },
  { latMetadata, lngMetadata }
) => {
  validateText(address);
  const latitudeValidation = checkFloat(
    copy,
    latitude,
    latMetadata.min,
    latMetadata.max
  );
  const longitudeValidation = checkFloat(
    copy,
    longitude,
    lngMetadata.min,
    lngMetadata.max
  );

  if (latitudeValidation.error || longitudeValidation.error) {
    return {
      value: {
        address,
        latitude,
        longitude,
      },
      error: true,
      helperText: copy.invalidAddress,
    };
  }

  if (!validPostalCode || !validStreetNumber)
    return {
      value: {
        address,
        latitude,
        longitude,
      },
      error: true,
      helperText: copy.incompleteAddress,
    };

  return {
    value: {
      address,
      latitude,
      longitude,
    },
    error: false,
    helperText: " ",
  };
};

const validateList = (
  copy,
  allowedValues,
  type,
  value,
  error = false,
  helperText = " "
) => {
  // mind the two "==" while performing the comparison
  // so that the variables' type doesn't interfere in the
  // comparison, ie, 2 == "2" gives true
  const isNotInAllowedValues = allowedValues
    .map((v) => value === v)
    .every((comp) => comp === false);

  if (isNotInAllowedValues) {
    error = true;

    helperText =
      type === "string"
        ? copy.invalidValue
        : copy.hasToBeBetween
            .replace("{first}", allowedValues[0])
            .replace("{last}", allowedValues[allowedValues.length - 1]);
  }

  return { value, error, helperText };
};

const checkRequired = (copy, value, error = false, helperText = " ") => {
  if (
    value === null ||
    value === undefined ||
    //value.replace(/\s/g, "") === "" ||
    value === "" ||
    (Array.isArray(value) && value.length === 0)
  ) {
    error = true;
    helperText = copy.mandatory;
  } else if (typeof value === "string" && value.replace(/\s/g, "") === "") {
    error = true;
    helperText = copy.mandatory;
  }
  return { value, error, helperText };
};

const checkInteger = (
  copy,
  value,
  min = null,
  max = null,
  error = false,
  helperText = " "
) => {
  if (!Number.isInteger(Number(value))) {
    error = true;
    helperText = copy.invalidValue;
  } else if (min && min > value) {
    error = true;
    helperText = copy.hasToBeAbove.replace("{value}", Math.ceil(min));
  } else if (max && max < value) {
    error = true;
    helperText = copy.hasToBeBelow.replace("{value}", Math.floor(max));
  }

  return { value, error, helperText };
};

const checkFloat = (
  copy,
  value,
  min = null,
  max = null,
  error = false,
  helperText = " "
) => {
  if (Number.isNaN(Number(value))) {
    error = true;
    helperText = copy.invalidValue;
  } else if (min && min > value) {
    error = true;
    helperText = copy.hasToBeAbove.replace("{value}", Math.ceil(min));
  } else if (max && max < value) {
    error = true;
    helperText = copy.hasToBeBelow.replace("{value}", Math.floor(max));
  }
  return { value, error, helperText };
};

const validateEmail = (copy, value, error = false, helperText = " ") => {
  const REGEX_EMAIL =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!REGEX_EMAIL.test(value)) {
    error = true;
    helperText = copy.invalidEmail;
  }
  return { value: value.trim().toLowerCase(), error, helperText };
};

const validatePhone = (copy, value, error = false, helperText = " ") => {
  // To limit portuguese numebrs to only 9 digits
  if (value.slice(0, 3) === "351") {
    const REGEX_PHONE = /^3519[1236][0-9]{7}$/;

    if (!REGEX_PHONE.test(value)) {
      error = true;
      helperText = copy.invalidPhone;
    }
  }

  return { value, error, helperText };
};

const validatePostCode = (copy, value, error = false, helperText = " ") => {
  const REGEX_POSTCODE = /\d{4}(-\d{3})?/;
  if (!REGEX_POSTCODE.test(value)) {
    error = true;
    helperText = copy.invalidPostalCode;
  }
  return { value, error, helperText };
};

const validateText = (value, error = false, helperText = " ") => ({
  value,
  error,
  helperText,
});

export { validateField, checkRequired, validateEmail, checkFloat };
