import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Grid, Chip } from "@material-ui/core";

const FormCharacteristic = (props) => {
  const { option, label, entry, handleEntryChange } = props;
  const { lead } = useSelector((state) => state.reducer);

  return (
    <FormField item>
      <Chip
        clickable
        color={lead.characteristics.includes(option) ? "primary" : "secondary"}
        onClick={() => handleEntryChange(entry.id, option, entry.validate)}
        label={label}
        style={{ fontSize: "16px" }}
      />
    </FormField>
  );
};

const FormField = styled(Grid)`
  padding: 5px 10px 5px 0px;
`;

export default FormCharacteristic;
