import React from "react";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import styled from "styled-components";

import { MAIN_TITLES, MAIN_SUBTITLES } from "../../data/copy";
import { PALLETE_SECONDARY_COLOR } from "../../theme/colors";

const Title = (props) => {
  const { title, subTitle, mobile } = props;
  const { lang, predict } = useSelector((state) => state.reducer);
  const copy = MAIN_TITLES[lang];
  const copySubtitles = MAIN_SUBTITLES[lang];

  const priceParser = (price) => {
    if (lang === "en")
      return `€${((price / 1e3).toFixed(0) * 1000).toLocaleString("en")}`;

    return `${((price / 1e3).toFixed(0) * 1000)
      .toLocaleString("en")
      .replace(/,/g, " ")} €`;
  };

  return (
    <Grid item xs={12}>
      <Typography variant="h3" style={{ fontSize: mobile ? "22px" : "30px" }}>
        {copy[title]}{" "}
        {title === "valuationResult" && (
          <Price color="secondary">{priceParser(predict.avg.gross)}</Price>
        )}
      </Typography>
      {copySubtitles[subTitle] && (
        <Subtitle variant="body1">{copySubtitles[subTitle]}</Subtitle>
      )}
    </Grid>
  );
};

const Price = styled.span`
  color: ${PALLETE_SECONDARY_COLOR};
`;

const Subtitle = styled(Typography)`
  padding-top: 30px;
`;

export default Title;
