import metadata from "./metadata-es";
import { validateField } from "../helpers/ValidateField";

const form = [
  {
    title: "propertyLocation",
    subTitle: "enterPropertyLocation",
    entries: [
      {
        id: "address",
        required: true,
        type: "autocomplete",
        validate: (object, lang) =>
          validateField(object, "address", true, lang, {
            latMetadata: metadata.latitude,
            lngMetadata: metadata.longitude,
          }),
        width: {
          xs: 12,
          sm: 12,
        },
      },
      {
        id: "assetType",
        required: true,
        type: "chipSelect",
        validate: (value, lang) =>
          validateField(value, "string", true, lang, metadata.assetType),
        width: {
          xs: 12,
          sm: 6,
        },
      },
    ],
    buttons: [
      {
        type: "start",
        width: {
          xs: 12,
          sm: 6,
        },
      },
    ],
  },
  {
    title: "mainFeatures",
    subTitle: "enterMainFeatures",
    entries: [
      {
        id: "condition",
        required: true,
        type: "select",
        validate: (value, lang) =>
          validateField(value, "string", true, lang, metadata.condition),
        width: {
          xs: 12,
          sm: 6,
        },
      },
      {
        id: "numberOfRooms",
        required: true,
        type: "select",
        validate: (value, lang) =>
          validateField(value, "integer", true, lang, metadata.numberOfRooms),
        width: {
          xs: 12,
          sm: 6,
        },
      },
      {
        id: "grossArea",
        required: true,
        type: "float",
        validate: (value, lang) =>
          validateField(value, "float", true, lang, metadata.grossArea),
        width: {
          xs: 12,
          sm: 6,
        },
      },
    ],
    buttons: [
      {
        type: "previous",
        width: {
          xs: 6,
          sm: 6,
        },
      },
      {
        type: "next",
        width: {
          xs: 6,
          sm: 6,
        },
      },
    ],
  },
  {
    title: "secondaryFeatures",
    subTitle: "enterSecondaryFeatures",
    entries: [
      {
        id: "buildingYear",
        required: false,
        type: "integer",
        validate: (value, lang) =>
          validateField(value, "integer", false, lang, metadata.buildingYear),
        width: {
          xs: 12,
          sm: 6,
        },
      },
      {
        id: "energyEfficiency",
        required: false,
        type: "select",
        validate: (value, lang) =>
          validateField(
            value,
            "string",
            false,
            lang,
            metadata.energyEfficiency
          ),
        width: {
          xs: 12,
          sm: 6,
        },
      },
      {
        id: "numberOfParkingSpots",
        required: false,
        type: "select",
        validate: (value, lang) =>
          validateField(
            value,
            "integer",
            false,
            lang,
            metadata.numberOfParkingSpots
          ),
        width: {
          xs: 12,
          sm: 6,
        },
      },

      {
        id: "numberOfWcs",
        required: false,
        type: "select",
        validate: (value, lang) =>
          validateField(value, "integer", false, lang, metadata.numberOfWcs),
        width: {
          xs: 12,
          sm: 6,
        },
      },
    ],
    buttons: [
      {
        type: "previous",
        width: {
          xs: 6,
          sm: 6,
        },
      },
      {
        type: "next",
        width: {
          xs: 6,
          sm: 6,
        },
      },
    ],
  },
  {
    title: "extraCharacteristics",
    subTitle: "selectExtraCharacteristics",
    entries: [
      {
        id: "characteristics",
        required: false,
        type: "list",
        validate: (value, lang) =>
          validateField(value, "list", false, lang, metadata.characteristics),
        width: {
          xs: 12,
          sm: 4,
        },
      },
    ],
    buttons: [
      {
        type: "previous",
        width: {
          xs: 6,
          sm: 6,
        },
      },
      {
        type: "next",
        width: {
          xs: 6,
          sm: 6,
        },
      },
    ],
  },
  {
    title: "personalDetails",
    entries: [
      {
        id: "owner",
        required: true,
        type: "chipSelect",
        validate: (value, lang) =>
          validateField(value, "string", true, lang, metadata.owner),
        width: {
          xs: 12,
          sm: 12,
        },
      },
      {
        id: "project",
        required: true,
        type: "chipSelect",
        validate: (value, lang) =>
          validateField(value, "string", true, lang, metadata.project),
        conditional: { key: "owner", value: true },
        width: {
          xs: 12,
          sm: 12,
        },
      },
      {
        id: "timeframe",
        required: true,
        type: "chipSelect",
        validate: (value, lang) =>
          validateField(value, "string", true, lang, metadata.timeframe),
        conditional: { key: "owner", value: true },
        width: {
          xs: 12,
          sm: 12,
        },
      },
      {
        id: "areyou",
        required: true,
        type: "chipSelect",
        validate: (value, lang) =>
          validateField(value, "string", true, lang, metadata.type),
        conditional: { key: "owner", value: false },
        width: {
          xs: 12,
          sm: 12,
        },
      },
    ],
    buttons: [
      {
        type: "previous",
        width: {
          xs: 6,
          sm: 6,
        },
      },
      {
        type: "next",
        width: {
          xs: 6,
          sm: 6,
        },
      },
    ],
  },
  {
    title: "estimateReady",
    subTitle: "insertPersonalDetails",
    entries: [
      {
        id: "firstName",
        required: true,
        type: "string",
        validate: (value, lang) => validateField(value, "string", true, lang),
        width: {
          xs: 12,
          sm: 6,
        },
      },
      {
        id: "lastName",
        required: true,
        type: "string",
        validate: (value, lang) => validateField(value, "string", true, lang),
        width: {
          xs: 12,
          sm: 6,
        },
      },
      {
        id: "phone",
        required: true,
        type: "string",
        validate: (value, lang) => validateField(value, "phone", true, lang),
        width: {
          xs: 12,
          sm: 6,
        },
      },
      {
        id: "email2",
        name: "e-mail",
        required: true,
        type: "string",
        validate: (value, lang) => validateField(value, "email", true, lang),
        width: {
          xs: 12,
          sm: 6,
        },
      },
    ],
    buttons: [
      {
        type: "previous",
        width: {
          xs: 6,
          sm: 6,
        },
      },
      {
        type: "submit",
        width: {
          xs: 6,
          sm: 6,
        },
      },
    ],
  },
  {
    title: "valuationResult",
    entries: [
      {
        id: "valuationResults",
        type: "predict",
        width: {
          xs: 12,
          sm: 12,
        },
      },
    ],
  },
];

export default form;
