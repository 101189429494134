import {
  SET_LEAD_FIELD,
  SET_LEAD_CLIENT_DETAIL,
  TOGGLE_LEAD_LIST_ELEMENT,
  TOGGLE_ERROR_LEAD_FIELD,
  TOGGLE_ERROR_LEAD_CLIENT_DETAIL,
  SET_LANGUAGE,
  SET_PREDICTION,
  SET_LEAD_ID,
  SET_ERROR,
  SET_KEY_VALUES,
  SET_AGENT_REFERENCE,
} from "./constants";

let lang = "pt";
if (typeof window !== `undefined`) {
  let storedLang = localStorage.getItem("lang");
  var userLang = storedLang || navigator.language || navigator.userLanguage;
  if (userLang.includes("en")) {
    lang = "en";
  }
}

const initalState = {
  form: null,
  metadata: null,
  error: null,
  lang: lang,
  realtor: null,
  postalCode: null,
  lead: {
    fields: {
      address: {
        value: "",
        error: false,
        helperText: " ",
      },
      grossArea: {
        value: "",
        error: false,
        helperText: " ",
      },
      latitude: {
        value: "",
        error: false,
        helperText: " ",
      },
      longitude: {
        value: "",
        error: false,
        helperText: " ",
      },
      addType: {
        value: "",
        error: false,
        helperText: " ",
      },
      assetType: {
        value: "",
        error: false,
        helperText: " ",
      },
      condition: {
        value: "",
        error: false,
        helperText: " ",
      },
      energyEfficiency: {
        value: "",
        error: false,
        helperText: " ",
      },
      numberOfParkingSpots: {
        value: "",
        error: false,
        helperText: " ",
      },
      numberOfRooms: {
        value: "",
        error: false,
        helperText: " ",
      },
      buildingYear: {
        value: "",
        error: false,
        helperText: " ",
      },
      numberOfWcs: {
        value: "",
        error: false,
        helperText: " ",
      },
      terrainArea: {
        value: "",
        error: false,
        helperText: " ",
      },
      usefulArea: {
        value: "",
        error: false,
        helperText: " ",
      },
    },
    characteristics: [],
  },
  client: {
    // name: {
    //   value: "",
    //   error: false,
    //   helperText: " ",
    // },
    firstName: {
      value: "",
      error: false,
      helperText: " ",
    },
    lastName: {
      value: "",
      error: false,
      helperText: " ",
    },
    email2: {
      value: "",
      error: false,
      helperText: " ",
    },
    phone: {
      value: "",
      error: false,
      helperText: " ",
    },
    owner: {
      value: "",
      error: false,
      helperText: " ",
    },
    project: {
      value: "",
      error: false,
      helperText: " ",
    },
    timeframe: {
      value: "",
      error: false,
      helperText: " ",
    },
    areyou: {
      value: "",
      error: false,
      helperText: " ",
    },
    // type: {
    //   value: "",
    //   error: false,
    //   helperText: " ",
    // },
    // sellProcess: {
    //   value: "",
    //   error: false,
    //   helperText: " ",
    // },
    // alreadyTriedToSell: {
    //   value: "",
    //   error: false,
    //   helperText: " ",
    // },
    // listedInAgency: {
    //   value: "",
    //   error: false,
    //   helperText: " ",
    // },
  },
  leadID: "",
  predict: {
    max: { gross: null, perSq: null },
    min: { gross: null, perSq: null },
    avg: { gross: null, perSq: null },
  },
  agent: {
    name: "",
    email: "",
    phone: "",
    image: "",
  },
};

/*
  ADD NEW LEAD PAYLOAD
  fields: {
    address: str = None
    grossArea: float !
    latitude: float !
    longitude: float !
    assetType: str !
    condition: str !
    energyEfficiency: Optional[str] = None !
    numberOfParkingSpots: Optional[int] = None !
    numberOfRooms: int !
    buildingYear: Optional[int] = None
    numberOfWcs: Optional[int] = None !
    terrainArea: Optional[float] = None !
    usefulArea: Optional[float] = None !
  }
  characteristics: List[str]
  image: Image = None
  images: List[str] = None
*/

const reducers = (state = initalState, action) => {
  switch (action.type) {
    case SET_KEY_VALUES:
      return { ...state, ...action.payload };
    case SET_LANGUAGE:
      localStorage.setItem("lang", action.lang);
      return { ...state, lang: action.lang };
    case SET_AGENT_REFERENCE:
      return { ...state, realtor: action.value };
    case SET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case SET_LEAD_ID:
      return {
        ...state,
        leadID: action.value,
      };
    case SET_PREDICTION:
      return {
        ...state,
        predict: action.value.predict,
        agent: action.value.agent,
      };
    case SET_LEAD_FIELD:
      if (state.lead.fields[action.key]) {
        return {
          ...state,
          lead: {
            ...state.lead,
            fields: {
              ...state.lead.fields,
              [action.key]: {
                ...state.lead.fields[action.key],
                value: action.value,
                fromDOM:
                  action.key === "address" && action.fromDOM ? true : false,
              },
            },
          },
        };
      }

      return state;

    case SET_LEAD_CLIENT_DETAIL:
      if (state.client[action.key]) {
        return {
          ...state,
          client: {
            ...state.client,
            [action.key]: {
              ...state.client[action.key],
              value: action.value,
            },
          },
        };
      }

      return state;

    case TOGGLE_LEAD_LIST_ELEMENT:
      if (state.lead[action.key] instanceof Array) {
        // If it already has the element, remove it, if not, add it
        if (state.lead[action.key].includes(action.element)) {
          const newList = [...state.lead[action.key]];
          newList.splice(newList.indexOf(action.element), 1);
          return {
            ...state,
            lead: {
              ...state.lead,
              [action.key]: newList,
            },
          };
        } else {
          return {
            ...state,
            lead: {
              ...state.lead,
              [action.key]: [...state.lead[action.key], action.element],
            },
          };
        }
      }

      return state;

    case TOGGLE_ERROR_LEAD_FIELD:
      if (state.lead.fields[action.key]) {
        return {
          ...state,
          lead: {
            ...state.lead,
            fields: {
              ...state.lead.fields,
              [action.key]: {
                ...state.lead.fields[action.key],
                error: action.error,
                helperText: action.helperText,
              },
            },
          },
        };
      }

      return state;

    case TOGGLE_ERROR_LEAD_CLIENT_DETAIL:
      if (state.client[action.key]) {
        return {
          ...state,
          client: {
            ...state.client,
            [action.key]: {
              ...state.client[action.key],
              error: action.error,
              helperText: action.helperText,
            },
          },
        };
      }

      return state;

    default:
      return state;
  }
};

export default reducers;
