export const SET_LEAD_FIELD = "SET_LEAD_FIELD";
export const SET_LEAD_CLIENT_DETAIL = "SET_LEAD_CLIENT_DETAIL";
export const TOGGLE_LEAD_LIST_ELEMENT = "TOGGLE_LEAD_LIST_ELEMENT";
export const TOGGLE_ERROR_LEAD_FIELD = "TOGGLE_ERROR_LEAD_FIELD";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const TOGGLE_ERROR_LEAD_CLIENT_DETAIL =
  "TOGGLE_ERROR_LEAD_CLIENT_DETAIL";
export const SET_PREDICTION = "SET_PREDICTION";
export const SET_LEAD_ID = "SET_LEAD_ID";
export const SET_ERROR = "SET_ERROR";
export const SET_KEY_VALUES = "SET_KEY_VALUES";
export const SET_AGENT_REFERENCE = "SET_AGENT_REFERENCE";
