import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import MuiPhoneNumber from "vena-material-ui-phone-number";

import { Grid, TextField, Typography } from "@material-ui/core";
import { FORM_FIELDS } from "../../data/copy";

const FormTextField = (props) => {
  const { entry, handleEntryChange, idx, $mobile, suppressTitle } = props;
  const { lead, client, lang } = useSelector((state) => state.reducer);
  const copy = FORM_FIELDS[lang];

  // It will either be a lead field or a client details field
  const object = lead.fields[entry.id] || client[entry.id];

  if (!copy.labels[entry.id]) {
    return;
  }

  const getLabel = () => {
    if (entry.id.includes("Area")) {
      return copy.textFieldLabels.area;
    } else {
      if (entry.id === "name") {
        return copy.textFieldLabels.name;
      } else if (entry.id === "firstName") {
        return copy.textFieldLabels.firstName;
      } else if (entry.id === "lastName") {
        return copy.textFieldLabels.lastName;
      } else if (entry.id === "email2") {
        return copy.textFieldLabels.email;
      } else if (entry.id === "buildingYear") {
        return copy.textFieldLabels.year;
      } else if (entry.id === "phone") {
        return copy.textFieldLabels.phone;
      } else {
        return copy.textFieldLabels.text;
      }
    }
  };

  const handleChangePhone = (event) => {
    // Remove every instance of: "+", "-", "()" and spaces
    const formattedPhoneNumber = event.target.value.replace(/[+-/(/)\s]/g, "");

    // To not raise errors if it only has the extention
    const REGEX_PHONE_EXTENSION_ONLY = /^[0-9]{2,3}$/;

    if (!REGEX_PHONE_EXTENSION_ONLY.test(formattedPhoneNumber)) {
      handleEntryChange(entry.id, formattedPhoneNumber, entry.validate);
    }
  };

  let labelToUse;
  if (entry.id === "grossArea" && process.env.REACT_APP_ORIGIN === "es")
    labelToUse = copy.labels.usefulArea;
  else labelToUse = copy.labels[entry.id];

  return (
    <FormField
      item
      xs={entry.width.xs}
      sm={entry.width.sm}
      idx={idx}
      $mobile={$mobile}
    >
      <Grid container>
        {!suppressTitle && (
          <Title item xs={12}>
            <Typography
              variant="subtitle1"
              style={{ fontSize: $mobile ? "16px" : "18px" }}
            >
              {entry.required ? `${labelToUse}*` : labelToUse}
            </Typography>
          </Title>
        )}
        <Grid item xs={12}>
          {entry.id === "phone" ? (
            <MuiPhoneNumber
              name="phone"
              error={object.error}
              helperText={object.helperText}
              placeholder={getLabel()}
              variant="outlined"
              regions={["europe"]}
              defaultCountry={
                lang === "en" ? "gb" : lang === "cat" ? "es" : lang
              }
              countryCodeEditable={false}
              onBlur={handleChangePhone}
            />
          ) : (
            <TextField
              name={entry.name || entry.id}
              type={
                ["float", "integer"].includes(entry.type) ? "number" : "text"
              }
              error={object.error}
              helperText={object.helperText}
              placeholder={getLabel()}
              variant="outlined"
              onBlur={(event) => {
                handleEntryChange(
                  entry.id,
                  event.target.value ? event.target.value : null,
                  entry.validate
                );
              }}
            />
          )}
        </Grid>
      </Grid>
    </FormField>
  );
};

const FormField = styled(Grid)`
  width: 100%;
  padding-left: ${(props) => props.idx % 2 > 0 && !props.$mobile && "5px"};
  padding-right: ${(props) => props.idx % 2 === 0 && !props.$mobile && "5px"};
`;

const Title = styled(Grid)`
  padding-bottom: 5px;
`;

export default FormTextField;
