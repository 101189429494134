async function sha256(message) {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message);

  // hash the message
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
}

const uploadToAnalytics = async (
  dataLayer = null,
  {
    error,
    step,
    predict,
    clientType,
    leadID,
    clientEmail,
    realtor,
    origin,
    postalCode,
  }
) => {
  if (!dataLayer) return;

  if (realtor)
    return uploadToAnalyticsAgent(dataLayer, {
      error,
      step,
      predict,
      clientType,
      leadID,
      clientEmail,
      realtor,
      origin,
      postalCode,
    });

  if (step !== undefined) {
    let formStep;
    let pagePath;

    if (step === 1) {
      formStep = "2_property_information";
      pagePath = "/estimation-form/property-information";
    } else if (step === 2) {
      formStep = "3_property_additional_information";
      pagePath = "/estimation-form/property-additional-information";
    } else if (step === 3) {
      formStep = "4_property_equipments";
      pagePath = "/estimation-form/property-equipments";
    } else if (step === 4) {
      formStep = "5_user_informations";
      pagePath = "/estimation-form/user-informations";
    } else if (step === 5) {
      formStep = "6_property_estimation";
      pagePath = "/estimation-form/property-estimation";
    } else if (step === 6) {
      formStep = "7_confirmation";
      pagePath = "/estimation-form/confirmation";
    } else return;

    dataLayer.push({
      event: "form",
      event_name: "form_step",
      form_name: "estimation_form",
      form_step: formStep,
      page_path: pagePath,
    });
  } else if (error) {
    dataLayer.push({
      event: "error_interaction",
      event_name: "error",
      error_type: error.id,
      error_message: error.message,
      error_form_name: "estimation_form",
    });
  } else if (predict) {
    const emailHashed = await sha256(clientEmail);
    dataLayer.push({
      event: "generate-lead",
      event_name: "generate_lead",
      property_value: predict.avg.gross,
      currency: "EUR",
      lead_type: "estimate",
      lead_id: leadID || null,
      lead_origin: "other",
      postal_code: origin === "pt" ? `PT_${postalCode}` : `ES_${postalCode}`,
      property_tag: null,
      property_type: null,
      property_id: null,
      mandate_id: null,
      property_name: null,
      collaborator_id: null,
      email: emailHashed,
    });

    if (clientType) {
      dataLayer.push({
        event: "standard",
        event_name: "estimation_lead_profil",
        event_detail: clientType,
      });
    }
  } else if (clientType) {
    dataLayer.push({
      event: "standard",
      event_name: "estimation_lead_profil",
      event_detail: clientType,
    });
  } else return;
};

const uploadToAnalyticsAgent = async (
  dataLayer,
  {
    error,
    step,
    predict,
    clientType,
    leadID,
    clientEmail,
    realtor,
    origin,
    postalCode,
  }
) => {
  if (step !== undefined) {
    let formStep;
    let pagePath;

    if (step === 1) {
      formStep = "2_property_information";
      pagePath = "/estimation-form/property-information";
    } else if (step === 2) {
      formStep = "3_property_additional_information";
      pagePath = "/estimation-form/property-additional-information";
    } else if (step === 3) {
      formStep = "4_property_equipments";
      pagePath = "/estimation-form/property-equipments";
    } else if (step === 4) {
      formStep = "5_user_informations";
      pagePath = "/estimation-form/user-informations";
    } else if (step === 5) {
      formStep = "6_property_estimation";
      pagePath = "/estimation-form/property-estimation";
    } else if (step === 6) {
      formStep = "7_confirmation";
      pagePath = "/estimation-form/confirmation";
    } else return;

    dataLayer.push({
      event: "form",
      event_name: "form_step",
      form_name: "estimation_form_agent",
      form_step: formStep,
      page_path: pagePath,
    });
  }

  if (predict) {
    const emailHashed = await sha256(clientEmail);
    dataLayer.push({
      event: "generate-lead",
      event_name: "generate_lead",
      lead_type: "estimation_form_agent",
      lead_origin: "collaborator_page",
      lead_id: leadID || null,
      email: emailHashed,
      currency: "EUR",
      postal_code: origin === "pt" ? `PT_${postalCode}` : `ES_${postalCode}`,
      collaborator_id: realtor,
    });
  }
};

export { uploadToAnalytics };
