export const MAIN_TITLES = {
  pt: {
    estimateProperty: "Estimar propriedade",
    propertyLocation: "Localização do imóvel",
    mainFeatures: "Informações principais",
    secondaryFeatures: "Características do imóvel",
    extraCharacteristics: "Características adicionais",
    personalDetails: "Sobre si",
    estimateReady: "A sua estimativa está pronta",
    valuationResult: "O seu imóvel está avaliado em",
  },
  en: {
    estimateProperty: "Estimate property",
    propertyLocation: "Property address",
    mainFeatures: "Main informations",
    secondaryFeatures: "Property's features",
    extraCharacteristics: "Additional features",
    personalDetails: "About you",
    estimateReady: "Your estimation is ready",
    valuationResult: "Your property is valued at",
  },
  fr: {
    estimateProperty: "Estimer mon bien",
    propertyLocation: "Localisation du bien",
    mainFeatures: "Informations principales",
    secondaryFeatures: "Caractéristiques du bien",
    extraCharacteristics: "Précisions concernant le bien",
    personalDetails: "Ce bien et vous",
    estimateReady: "Votre estimation est prête",
    valuationResult: "Votre bien est estimé à",
  },
  es: {
    estimateProperty: "Estimer mon bien",
    propertyLocation: "Dirección de la propiedad",
    mainFeatures: "Información principal",
    secondaryFeatures: "Características del inmueble",
    extraCharacteristics: "Características adicionales",
    personalDetails: "Sobre ti",
    estimateReady: "Tu valoración está lista​",
    valuationResult: "La valoración del inmueble se estima en",
  },
  cat: {
    estimateProperty: "Estimer mon bien",
    propertyLocation: "Adreça de la propietat",
    mainFeatures: "Informació principal",
    secondaryFeatures: "Característiques de l'immoble",
    extraCharacteristics: "Característiques addicionals",
    personalDetails: "Sobre tu",
    estimateReady: "La teva valoració està llesta​",
    valuationResult: "La valoració de l'immoble s'estima en",
  },
};

export const MAIN_SUBTITLES = {
  pt: {
    enterPropertyLocation: " ",
    enterMainFeatures: " ",
    enterSecondaryFeatures: " ",
    selectExtraCharacteristics: "Selecione outras características do seu imóvel",
    insertPersonalDetails: "Introduza os seus dados para receber a sua avaliação por email",
    SMSValidation: " ",
  },
  en: {
    enterPropertyLocation: " ",
    enterMainFeatures: " ",
    enterSecondaryFeatures: " ",
    selectExtraCharacteristics: "Select other features of the property",
    insertPersonalDetails: "Enter your contact details to receive it by email",
    SMSValidation: " ",
  },
  fr: {
    enterPropertyLocation: " ",
    enterMainFeatures: " ",
    enterSecondaryFeatures: " ",
    selectExtraCharacteristics: "Sélectionnez d'autres caractéristiques du bien",
    insertPersonalDetails: "Entrez vos coordonnées pour recevoir votre estimation par email",
    SMSValidation: " ",
  },
  es: {
    enterPropertyLocation: " ",
    enterMainFeatures: " ",
    enterSecondaryFeatures: " ",
    selectExtraCharacteristics: "Selecciona otras características del inmueble",
    insertPersonalDetails:
      "Introduce tus datos para recibir la valoración estimada del inmueble por correo electrónico",
    SMSValidation: " ",
  },
  cat: {
    enterPropertyLocation: " ",
    enterMainFeatures: " ",
    enterSecondaryFeatures: " ",
    selectExtraCharacteristics: "Selecciona altres característiques de l'immoble",
    insertPersonalDetails:
      "Introdueix les teves dades de contacte per rebre el resultat per correu electrònic",
    SMSValidation: " ",
  },
};

export const PAGE_TITLES = {
  pt: {
    leadCharacteristics: "Características do imóvel",
    clientDetails: "Detalhes Pessoais",
    smsValidation: "Validação SMS",
    loading: "Estimativa em curso, por favor aguarde...",
  },
  en: {
    smsValidation: "SMS Validation",
    loading: "Getting estimation, please wait...",
  },
  fr: {
    smsValidation: "Validation SMS",
    loading: "Estimation en cours, merci de patienter...",
  },
  es: {
    smsValidation: "Validación de SMS",
    loading: "Obteniendo estimación, por favor espere...",
  },
  cat: {
    smsValidation: "Validación de SMS",
    loading: "Obtenint estimació, si us plau esperi...",
  },
};

export const PREDICTION = {
  pt: {
    valuationResult: "A sua propriedade está estimada em",
    max: "Valor máximo",
    avg: "Valor médio",
    min: "Valor mínimo",
    description: "Propriedade com {area} m² em {address}",
    confidenceLevel: "Grau de confiança",
    agent: "O profissional da área escolhido para o(a) acompanhar",
    sendEmail: "Enviar email",
    call: "Telefonar",
    disclaimer:
      "Esta estimativa é baseada em dados históricos de vendas e em dados do mercado na vizinhança do imóvel. O intervalo de valores apresentados deve ser analisado por um profissional que tenha em consideração todas as características do seu imóvel.",
  },
  en: {
    valuationResult: "Your property is estimated at",
    max: "Maximum value",
    avg: "Average value",
    min: "Minimum value",
    description: "Property with {area}m² in {address}",
    confidenceLevel: "Confidence level",
    agent: "The local professional chosen to discuss your valuation",
    sendEmail: "Send email",
    call: "Call",
    disclaimer:
      "This estimate is based on historical sales data and market data for the area in which the property is located. The range of values presented must be analyzed by a professional who takes into account all the characteristics of your property.",
  },
  fr: {
    valuationResult: "Votre bien est estimé à",
    max: "Valeur maximum",
    avg: "Valeur moyenne",
    min: "Valeur minimum",
    description: "Propriété de {area}m² à {address}",
    confidenceLevel: "Niveau de confiance",
    agent: "Le professionnel local choisi pour vous accompagner",
    sendEmail: "Mail",
    call: "Appeler",
    disclaimer:
      "Cette estimation est basée sur les données historiques des ventes et sur les données du marché dans le quartier du bien. La fourchette de valeurs présentée doit être analysée par un professionnel qui prendra en compte toutes les caractéristiques de votre bien.",
  },
  es: {
    valuationResult: "La valoración del inmueble se estima en",
    max: "Valoración a la alza",
    avg: "Precio estimado",
    min: "Valoración a la baja",
    description: "",
    confidenceLevel: "Grado de confianza",
    agent: "El profesional elegido para afinar tu valoración",
    sendEmail: "",
    call: "",
    disclaimer:
      "Esta valoración se basa en datos históricos de ventas y datos de mercado del área en la que se encuentra la propiedad. El rango de valores presentado debe ser analizado por un profesional que tenga en cuenta todas las características de tu propiedad.",
  },
  cat: {
    valuationResult: "La valoració de l'immoble s'estima en",
    max: "Valoració a la baixa",
    avg: "Preu estiat",
    min: "Valoració a l'alça",
    description: "",
    confidenceLevel: "Grau de confiança",
    agent: "El professional triat per afinar la teva valoració",
    sendEmail: "",
    call: "",
    disclaimer:
      "Aquesta valoració es basa en dades històriques de vendes i dades de mercat de l'àrea on es troba la propietat. El rang de valors presentat l'ha d'analitzar un professional que tingui en compte totes les característiques de la teva propietat.",
  },
};

export const FORM_ERRORS = {
  pt: {
    mandatory: "Campo obrigatório",
    hasToBeAbove: "O valor introduzido tem que ser superior a {value}",
    hasToBeBelow: "O valor introduzido tem que ser inferior a {value}",
    hasToBeBetween: "O valor introduzido tem que ser entre {first} e {last}",
    invalidValue: "Valor inválido",
    invalidAddress: "Morada inválida",
    incompleteAddress:
      "Por favor introduza uma morada completa. Ex: Av. dos Aliados 10, 4000-196 Porto",
    invalidEmail: "Endereço de email inválido",
    invalidPhone: "Número de telefone inválido",
    invalidPostalCode: "Código postal inválido",
  },
  en: {
    mandatory: "Mandatory field",
    hasToBeAbove: "The value has to be higher than {value}",
    hasToBeBelow: "The value has to be lower than {value}",
    hasToBeBetween: "The value has to be between {first} and {last}",
    invalidValue: "Invalid value",
    invalidAddress: "Invalid address",
    incompleteAddress: "Please provide a full address",
    invalidEmail: "Invalid email address",
    invalidPhone: "Invalid phone number",
    invalidPostalCode: "Invalid postal code",
  },
  fr: {
    mandatory: "Champ obligatoire",
    hasToBeAbove: "La valeur saisie doit être supérieure à {value}",
    hasToBeBelow: "La valeur saisie doit être inférieure à {value}",
    hasToBeBetween: "La valeur saisie doit être comprise entre {first} et {last}",
    invalidValue: "Valeur invalide",
    invalidAddress: "Adresse invalide",
    incompleteAddress: "Merci de renseigner une adresse complète",
    invalidEmail: "Adresse e-mail invalide",
    invalidPhone: "Numéro de téléphone invalide",
    invalidPostalCode: "Code postal invalide",
  },
  es: {
    mandatory: "Campo obligatorio",
    hasToBeAbove: "El valor tiene que ser mayor que {value}",
    hasToBeBelow: "El valor tiene que ser inferior a {value}",
    hasToBeBetween: "El valor tiene que estar entre {first} y {last}",
    invalidValue: "Valor no válido",
    invalidAddress: "Dirección inválida",
    incompleteAddress: "Introduce la dirección exacta. Ej: Carrer de Manso, 10, 08015 Barcelona",
    invalidEmail: "Correo electrónico no válido",
    invalidPhone: "Numero de telefono invalido",
    invalidPostalCode: "Código postal no válido",
  },
  cat: {
    mandatory: "Camp obligatori",
    hasToBeAbove: "El valor ha de ser major que {value}",
    hasToBeBelow: "El valor ha de ser inferior a {value}",
    hasToBeBetween: "El valor ha d'estar entre {first} i {last}",
    invalidValue: "Valor no vàlid",
    invalidAddress: "Adreça no vàlida",
    incompleteAddress: "Introdueix l'adreça exacta. Ex: Carrer de Manso, 10, 08015 Barcelona",
    invalidEmail: "Correu electronic no vàlid​",
    invalidPhone: "Número de telèfon no vàlid​",
    invalidPostalCode: "Codi postal no vàlid",
  },
};

export const FORM_BUTTONS = {
  pt: {
    start: "Validar",
    previous: "Voltar",
    next: "Próximo",
    submit: "Descobrir",
    validate: "Validar",
  },
  en: {
    start: "Submit",
    previous: "Back",
    next: "Next",
    submit: "Discover",
    validate: "Validate",
  },
  fr: {
    start: "Valider",
    previous: "Retour",
    next: "Suivant",
    submit: "Découvrir",
    validate: "Valider",
  },
  es: {
    start: "Validar",
    previous: "Anterior",
    next: "Siguiente",
    submit: "Descubrir",
    validate: "",
  },
  cat: {
    start: "Validar",
    previous: "Previ",
    next: "Següent",
    submit: "Descobrir",
    validate: "",
  },
};

export const SMS_VALIDATION = {
  pt: {
    smsValidation: "Validação SMS",
    invalidSMSCode: "Código inválido, por favor tente novamente",
    maxValidationsReached: "Número máximo de tentativas excedido",
    error: "Ocorreu um erro, por favor tente mais tarde",
    description:
      "Este passo é necessário de modo a fornecer uma camada de segurança extra. Por favor insira o código que recebeu no seu telemóvel para se autenticar e proceder com a avaliação.",
  },
  en: {
    smsValidation: "SMS Validation",
    invalidSMSCode: "Invalid code, please try again",
    maxValidationsReached: "Maximum number of tries reached",
    error: "An error has occured, please try again later",
    description:
      "This is a mandatory step to proceed with the price estimation. Please insert here the code received by SMS.",
  },
  fr: {
    smsValidation: "Validation SMS",
    invalidSMSCode: "Code invalide. S'il vous plaît essayer à nouveau",
    maxValidationsReached: "Nombre maximum d'essais atteint",
    error: "Un erreur est survenue, veuillez réessayer plus tard",
    description:
      "Nous avons besoin de cette étape afin de fournir une couche de sécurité supplémentaire. Veuillez insérer le code que vous avez reçu sur votre téléphone portable ci-dessous pour vous authentifier et procéder à l'évaluation.",
  },
};

export const FORM_FIELDS = {
  pt: {
    step: "Passo",
    of: "de",
    continue: "CONTINUAR",
    submit: "SUBMETER",
    invalidLatLng: "A morada introduzida tem que ser em Portugal",
    labels: {
      address: "Morada",
      characteristics: "Selecione outras características que tem o seu imóvel",
      name: "O seu nome",
      firstName: "Nome",
      lastName: "Apelido",
      email2: "O seu email",
      phone: "O seu número de telemóvel",
      assetType: "Tipo de imóvel",
      buildingYear: "Ano de construção",
      condition: "Estado",
      energyEfficiency: "Certificado energético",
      grossArea: "Área bruta privativa",
      numberOfParkingSpots: "Lugares estacionamento",
      numberOfRooms: "Tipologia",
      numberOfWcs: "Casas de banho",
      terrainArea: "Área de terreno",
      usefulArea: "Área útil",
      latitude: "Latitude",
      longitude: "Longitude",
      owner: "É proprietário do imóvel?",
      project: "Qual é o seu projeto?",
      timeframe: "Em que prazo?",
      areyou: "É um:",
      type: "Eu sou",
      sms: "Código SMS",
      sellProcess: "Em que fase está o processo de venda do seu imóvel?",
      alreadyTriedToSell: "Já colocou o seu imóvel à venda?",
      listedInAgency: "Recorreu a alguma agência imobiliária?",
      mandatoryField: "*Campo obrigatório",
      privacyPolicy:
        "Os dados fornecidos são tratados pela {IAD_ORIGIN} na qualidade de controlador de dados para efeitos de processamento do seu pedido e para o colocar em contacto com um consultor da iad. Tem o direito de acesso, retificação, oposição, apagamento e portabilidade dos seus dados. Para saber mais sobre o tratamento dos seus dados e os seus direitos consulte por favor a <a>política de privacidade.",
      // "Os dados fornecidos são tratados pela {IAD_ORIGIN} na qualidade de controlador de dados para efeitos de processamento do seu pedido e para o colocar em contacto com um consultor da iad. Tem o direito de acesso, retificação, oposição, apagamento e portabilidade dos seus dados. Para saber mais sobre o tratamento dos seus dados e os seus direitos consulte por favor a <a>política de privacidade.",
    },
    textFieldLabels: {
      year: "Indique um ano",
      area: "Em m²",
      select: "Selecione uma opção",
      text: "Introduza um valor",
      name: "Introduza o nome completo",
      firstName: "Nome*",
      lastName: "Apelido*",
      email: "Email*: nome@gmail.com",
    },
    options: {
      characteristics: {
        wardrobe: "Roupeiro embutido",
        gym: "Ginásio",
        airConditioning: "Ar condicionado",
        alarm: "Alarme",
        cityView: "Vista de cidade",
        closedGarage: "Garagem",
        pool: "Piscina",
        balcony: "Varanda",
        elevator: "Elevador",
        storage: "Arrecadação",
        electricBlinds: "Estores eletrónicos",
        parking: "Estacionamento",
        equippedKitchen: "Cozinha equipada",
        videoSecurity: "Vídeo-porteiro",
        fireplace: "Lareira",
        garden: "Jardim",
        centralHeating: "Aquecimento central",
        suite: "Suite",
        pantry: "Despensa",
        doubleWindows: "Vidros duplos",
        goodLightExposure: "Exposição solar",
        basement: "Cave",
        terrace: "Terraço",
        seaView: "Vista de mar",
        fakeCeiling: "Teto falso",
        mezzanine: "Mezanino",
        furnished: "Mobilado",
        commonPool: "Piscina comum",
        securityService: "Vigilância/segurança",
        office: "Escritório",
        highSecurityDoor: "Porta blindada",
        woodenFloor: "Soalho de madeira",
        patio: "Pátio",
      },
      assetType: {
        apartment: "Apartamento",
        house: "Moradia",
      },
      addType: {
        sell: "Comprar",
        rent: "Arrendar",
      },
      condition: {
        new: "Novo",
        used: "Usado",
        renovated: "Renovado",
        inConstruction: "Em construção",
        forRecovery: "Para recuperação",
        ruin: "Em ruína",
        almostNew: "Como novo",
      },
      numberOfRooms: {
        0: "T0",
        1: "T1",
        2: "T2",
        3: "T3",
        4: "T4",
        5: "T5",
        6: "T6",
        7: "T7",
        8: "T8",
        9: "T9",
        10: "T10",
        11: "T11",
        12: "T12",
        13: "T13",
      },
      // numberOfDivisions: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
      numberOfParkingSpots: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      numberOfWcs: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      energyEfficiency: ["NC", "G", "F", "E", "D", "C", "B-", "B", "A", "A+"],
      owner: {
        true: "Sim",
        false: "Não",
      },
      project: {
        sell: "Quero vender",
        buy: "Quero comprar",
        other: "Outro projeto",
      },
      timeframe: {
        ongoing: "O meu imóvel já está para venda",
        "3months": "Dentro de 3 meses",
        "3to6months": "Entre 3 e 6 meses",
        "6monthsOrMore": "Mais de 6 meses",
        estimate: "Só quero fazer uma estimativa",
      },
      areyou: {
        buy: "Comprador",
        professional: "Profissional imobiliário",
        other: "Outro",
      },
      type: {
        buy: "Comprador",
        sell: "Proprietário",
        visit: "Visitante",
        professional: "Profissional do imobiliário",
      },
      sellProcess: {
        start: "Quero iniciar o processo de venda",
        ongoing: "O meu imóvel já está no mercado",
        estimate: "Ainda não quero vender o meu imóvel, apenas estimar o seu valor",
      },
      alreadyTriedToSell: {
        true: "Sim",
        false: "Não",
      },
      listedInAgency: {
        true: "Sim",
        false: "Não",
      },
    },
  },
  en: {
    step: "Step",
    of: "de",
    continue: "CONTINUE",
    submit: "SUBMIT",
    invalidLatLng: "Input address must be in Portugal",
    labels: {
      address: "Address",
      characteristics: "Select other features of the property",
      name: "Your name",
      firstName: "Name",
      lastName: "Surname",
      email2: "Your email address",
      phone: "Your phone number",
      assetType: "Property type",
      buildingYear: "Building year",
      condition: "Condition",
      energyEfficiency: "Energy certificate",
      grossArea: "Gross area",
      numberOfParkingSpots: "Parking spots",
      numberOfRooms: "Number of bedrooms",
      numberOfWcs: "Number of bathrooms",
      terrainArea: "Terrain area",
      usefulArea: "Useful area",
      latitude: "Latitude",
      longitude: "Longitude",
      owner: "Do you own this property?",
      project: "What is your project?",
      timeframe: "What is the timeframe?",
      areyou: "Are you a:",
      type: "I am a",
      sms: "SMS Code",
      sellProcess: "At what stage is the process of selling your property?",
      alreadyTriedToSell: "Have you ever tried to sell your property?",
      listedInAgency: "Did you use any real estate agency?",
      mandatoryField: "*Mandatory field",
      privacyPolicy:
        "The data you transmit is processed by {IAD_ORIGIN} as data controller for the purposes of processing your request and putting you in touch with an iad advisor. You have a right of access, rectification, opposition, erasure, limitation and portability of your data. To learn more about the processing of your data by {IAD_ORIGIN} and your rights, please consult the <a>Privacy Policy.",
    },
    textFieldLabels: {
      year: "Pick a year",
      area: "In sq. meters",
      select: "Select an option",
      text: "Enter a value",
      firstName: "Name*",
      lastName: "Surname*",
      name: "Enter your full name",
      email: "Email*: name@gmail.com",
    },
    options: {
      characteristics: {
        doubleWindows: "Double glazed windows",
        suite: "Suite",
        cityView: "City view",
        terrace: "Terrace",
        garden: "Garden",
        electricBlinds: "Electric blinds",
        balcony: "Balcony",
        gym: "Gym",
        alarm: "Alarm",
        basement: "Basement",
        pool: "Pool",
        airConditioning: "Air conditioning",
        pantry: "Pantry",
        closedGarage: "Garage",
        equippedKitchen: "Equipped Kitchen",
        fireplace: "Fireplace",
        elevator: "Elevator",
        storage: "Storage Room",
        centralHeating: "Central Heating",
        videoSecurity: "Video surveillance",
        goodLightExposure: "Sun exposure",
        seaView: "Sea view",
        parking: "Parking space",
        wardrobe: "Wardrobe",
        fakeCeiling: "Fake ceiling",
        mezzanine: "Mezzanine",
        furnished: "Furnished",
        commonPool: "Common pool",
        securityService: "Security service",
        office: "Office",
        highSecurityDoor: "High security door",
        woodenFloor: "Wooden floor",
        patio: "Patio",
      },
      assetType: {
        apartment: "Apartment",
        house: "House",
      },
      addType: {
        sell: "Sell",
        rent: "Rent",
      },
      condition: {
        new: "New",
        used: "Used",
        renovated: "Renovated",
        inConstruction: "New construction",
        forRecovery: "For recovery",
        ruin: "Ruin",
        almostNew: "Almost new",
      },
      numberOfRooms: {
        0: "0",
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        6: "6",
        7: "7",
        8: "8",
        9: "9",
        10: "10",
        11: "11",
        12: "12",
        13: "13",
      },
      // numberOfDivisions: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
      numberOfParkingSpots: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      numberOfWcs: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      energyEfficiency: ["NC", "G", "F", "E", "D", "C", "B-", "B", "A", "A+"],
      owner: {
        true: "Yes",
        false: "No",
      },
      project: {
        sell: "I want to sell",
        buy: "I want to buy",
        other: "Other project",
      },
      timeframe: {
        ongoing: "My property is already for sale",
        "3months": "Within 3 months",
        "3to6months": "Within 6 months",
        "6monthsOrMore": "More than 6 months",
        estimate: "Only estimate",
      },
      areyou: {
        buy: "Buyer",
        professional: "Real estate professional",
        other: "Other",
      },
      type: {
        buy: "Buyer",
        sell: "Seller",
        visit: "Visitor",
        professional: "Real estate professional",
      },
      sellProcess: {
        start: "I want to start selling my property",
        ongoing: "My property is already on the market",
        estimate: "I don't want to sell my property yet, only know its value",
      },
      alreadyTriedToSell: {
        true: "Yes",
        false: "No",
      },
      listedInAgency: {
        true: "Yes",
        false: "No",
      },
    },
  },
  fr: {
    step: "Étape",
    of: "sur",
    continue: "CONTINUEZ",
    submit: "SUBMIT",
    invalidLatLng: "L'adresse doit être au Portugal",
    labels: {
      address: "Adresse",
      characteristics: "Sélectionnez d'autres caractéristiques du bien",
      name: "Nom et prénom",
      firstName: "Prénom",
      lastName: "Nom",
      email2: "Adresse Email",
      phone: "Numéro de téléphone",
      assetType: "Type de bien",
      buildingYear: "Année de construction",
      condition: "État du bien",
      energyEfficiency: "Certificat énergétique",
      grossArea: "Surface brute",
      numberOfParkingSpots: "Places de parking",
      numberOfRooms: "Nombre de chambres",
      numberOfWcs: "Nombre de salles de bain",
      terrainArea: "Aire de terrain",
      usefulArea: "Surface habitable",
      latitude: "Latitude",
      longitude: "Longitude",
      owner: "Êtes-vous propriétaire du bien ?",
      project: "Quel est votre projet ?",
      timeframe: "Sous quel délai ?",
      areyou: "Êtes-vous ?",
      type: "Je suis un",
      sms: "Code SMS",
      sellProcess: "A quelle étape se situe le processus de vente de votre bien?",
      alreadyTriedToSell: "Avez-vous déjà essayé de vendre votre propriété?",
      listedInAgency: "Avez-vous fait appel à une agence immobilière?",
      mandatoryField: "Champ obligatoire",
      privacyPolicy:
        "Les données que vous transmettez sont traitées par {IAD_ORIGIN} en qualité de responsable du traitement aux fins de traiter votre demande et de vous mettre en relation avec un conseiller iad. Vous disposez d’un droit d’accès, de rectification, d’opposition, d’effacement, de limitation et de portabilité de vos données. Pour en savoir plus sur le traitement de vos données par {IAD_ORIGIN} et sur vos droits, veuillez consulter la <a>Politique de confidentialité.",
    },
    textFieldLabels: {
      year: "Choisir une année",
      area: "En m²",
      select: "Choisir une option",
      text: "Entrez une valeur",
      firstName: "Prénom*",
      lastName: "Nom*",
      name: "Indiquez votre nom et prénom",
      email: "Email* : nom@gmail.com",
    },
    options: {
      characteristics: {
        doubleWindows: "Double vitrage",
        suite: "Suite",
        cityView: "Vue sur la ville",
        terrace: "Terrasse",
        garden: "Jardin",
        electricBlinds: "Volets électriques",
        balcony: "Balcon",
        gym: "Gym",
        alarm: "Alarme",
        basement: "Cave",
        pool: "Piscine",
        airConditioning: "Climatisation",
        pantry: "Garde-manger",
        closedGarage: "Garage",
        equippedKitchen: "Cuisine équipée",
        fireplace: "Cheminée",
        elevator: "Ascenseur",
        storage: "Remise",
        centralHeating: "Chauffage central",
        videoSecurity: "Vidéosurveillance",
        goodLightExposure: "Exposition au soleil",
        seaView: "Vue sur la mer",
        parking: "Place de parking",
        wardrobe: "Penderie",
        fakeCeiling: "Faux plafond",
        mezzanine: "Mezzanine",
        furnished: "Meublée",
        commonPool: "Piscine commune",
        securityService: "Service de sécurité",
        office: "Bureau",
        highSecurityDoor: "Porte haute sécurité",
        woodenFloor: "Sol en bois",
        patio: "Patio",
      },
      assetType: {
        apartment: "Appartement",
        house: "Maison",
      },
      condition: {
        forRecovery: "À renover",
        inConstruction: "En construction",
        new: "Neuf",
        renovated: "Renové",
        ruin: "En ruine",
        used: "Ancien",
      },
      numberOfRooms: {
        0: "0",
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        6: "6",
        7: "7",
        8: "8",
        9: "9",
        10: "10",
        11: "11",
        12: "12",
        13: "13",
      },
      numberOfParkingSpots: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      numberOfWcs: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      energyEfficiency: ["NC", "G", "F", "E", "D", "C", "B-", "B", "A", "A+"],
      owner: {
        true: "Oui",
        false: "Non",
      },
      project: {
        sell: "Je veux vendre",
        buy: "Je veux acheter",
        other: "Autre projet",
      },
      timeframe: {
        ongoing: "Mon bien est déjà à vendre",
        "3months": "D'ici 3 mois",
        "3to6months": "Entre 3 et 6 mois",
        "6monthsOrMore": "Plus de 6 mois",
        estimate: "Je veux seulement estimer",
      },
      areyou: {
        buy: "Acquéreur",
        professional: "Professionnel de l'immobilier",
        other: "Autre",
      },
      type: {
        buy: "Acheteur",
        sell: "Propriétaire",
        visit: "Visiteur",
        professional: "Professionnel de l’immobilier",
      },
      sellProcess: {
        start: "Je veux commencer à vendre mon bien",
        ongoing: "Ma propriété est déjà sur le marché",
        estimate: "Je ne veux pas encore vendre ma propriété, je connais seulement sa valeur",
      },
      alreadyTriedToSell: {
        true: "Oui",
        false: "Non",
      },
      listedInAgency: {
        true: "Oui",
        false: "Non",
      },
    },
  },
  es: {
    step: "Step",
    of: "de",
    continue: "CONTINUE",
    submit: "SUBMIT",
    invalidLatLng: "Input address must be in Portugal",
    labels: {
      address: "Dirección",
      characteristics: "Selecciona otras características del inmueble",
      name: "",
      firstName: "Nombre",
      lastName: "Apellido",
      email2: "Email",
      phone: "Teléfono",
      assetType: "Tipo de propiedad",
      buildingYear: "Año de construcción",
      condition: "Estado",
      energyEfficiency: "Certificado energético",
      grossArea: "Superfície construída",
      numberOfParkingSpots: "Plazas de parking",
      numberOfRooms: "Número de habitaciones",
      numberOfWcs: "Número de baños",
      terrainArea: "",
      usefulArea: "Superfície útil",
      latitude: "Latitude",
      longitude: "Longitude",
      owner: "¿Eres el/la propietario/a de la propiedad?",
      project: "¿Cuál es tu proyecto?",
      timeframe: "¿Cuál es el plazo de tu proyecto?",
      areyou: "Eres:",
      type: "",
      sms: "",
      sellProcess: "",
      alreadyTriedToSell: "",
      listedInAgency: "",
      mandatoryField: "*Campo obligatorio",
      privacyPolicy:
        "Los datos que usted proporciona son tratados por {IAD_ORIGIN} como responsable del tratamiento de datos con el fin de crear y gestionar su cuenta. Tiene derecho a acceder, rectificar, oponerse, eliminar, limitar y portar sus datos. Para saber más sobre el tratamiento de sus datos por parte de {IAD_ORIGIN} y sus derechos, consulte la <a>menciones legales.",
    },
    textFieldLabels: {
      year: "Elige un año",
      area: "En m²",
      select: "Selecciona una opción",
      text: "Introduce un valor",
      firstName: "Nombre*",
      lastName: "Apellido*",
      name: "",
      email: "Email*: nombre@gmail.com",
    },
    options: {
      characteristics: {
        doubleWindows: "Doble acristalamiento",
        suite: "Suite",
        cityView: "Vista de la ciudad",
        terrace: "Terraza",
        garden: "Jardín",
        electricBlinds: "Estores eléctricos",
        balcony: "Balcón",
        gym: "Gimnasio",
        alarm: "Alarma",
        basement: "Sótano",
        pool: "Piscina",
        airConditioning: "Aire acondicionado",
        pantry: "Despensa",
        closedGarage: "Garaje cerrado",
        equippedKitchen: "Cocina equipada",
        fireplace: "Chimenea",
        elevator: "Ascensor",
        storage: "Almacenamiento",
        centralHeating: "Calefacción",
        videoSecurity: "Video vigilancia",
        goodLightExposure: "Luminoso",
        seaView: "Vistas al mar",
        parking: "Plaza de parking​",
        wardrobe: "Ropero",
        fakeCeiling: "Falso techo",
        mezzanine: "Entresuelo",
        furnished: "Amueblado",
        commonPool: "Piscina común",
        securityService: "Vigilante/seguridad",
        office: "Oficina",
        highSecurityDoor: "Puertas blindadas",
        woodenFloor: "Suelo de madera",
        patio: "Patio",
      },
      assetType: {
        apartment: "Piso",
        house: "Casa",
      },
      addType: {
        sell: "Comprar",
        rent: "Alquilar",
      },
      condition: {
        new: "Nuevo",
        used: "Segunda mano",
        renovated: "Reformado",
        inConstruction: "En construcción",
        forRecovery: "A reformar",
        ruin: "Ruína",
        almostNew: "Como nuevo",
      },
      numberOfRooms: {
        0: "0",
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        6: "6",
        7: "7",
        8: "8",
        9: "9",
        10: "10",
        11: "11",
        12: "12",
        13: "13",
      },
      // numberOfDivisions: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
      numberOfParkingSpots: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      numberOfWcs: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      energyEfficiency: ["NC", "G", "F", "E", "D", "C", "B-", "B", "A", "A+"],
      owner: {
        true: "Sí",
        false: "No",
      },
      project: {
        sell: "Quiero vender",
        buy: "Quiero comprar",
        other: "Otro proyecto",
      },
      timeframe: {
        ongoing: "Mi propiedad ya está a la venta",
        "3months": "Dentro de 3 meses",
        "3to6months": "Dentro de 6 meses",
        "6monthsOrMore": "Más de 6 meses",
        estimate: "Sólo quiero saber el precio",
      },
      areyou: {
        buy: "Comprador",
        professional: "Profesional inmobiliario",
        other: "Otro",
      },
      type: {
        buy: "",
        sell: "",
        visit: "",
        professional: "",
      },
      sellProcess: {
        start: "",
        ongoing: "",
        estimate: "",
      },
      alreadyTriedToSell: {
        true: "",
        false: "",
      },
      listedInAgency: {
        true: "",
        false: "",
      },
    },
  },
  cat: {
    step: "",
    of: "",
    continue: "",
    submit: "",
    invalidLatLng: "Input address must be in Portugal",
    labels: {
      address: "Adreça",
      characteristics: "Selecciona altres característiques de l'immoble",
      name: "",
      firstName: "Nombre",
      lastName: "Apellido",
      email2: "Email",
      phone: "Teléfono",
      assetType: "Tipus de propietat",
      buildingYear: "Any de construcció",
      condition: "Estat",
      energyEfficiency: "Certificat energètic",
      grossArea: "Superfície construïda",
      numberOfParkingSpots: "Places de pàrquing",
      numberOfRooms: "Nombre de habitacions",
      numberOfWcs: "Nombre de banys",
      terrainArea: "",
      usefulArea: "Superfície útil",
      latitude: "Latitude",
      longitude: "Longitude",
      owner: "Ets el/la propietari/a de la propietat?	",
      project: "Quin és teu projecte?	",
      timeframe: "Quin és el termini del teu projecte?",
      areyou: "Ets:",
      type: "",
      sms: "",
      sellProcess: "",
      alreadyTriedToSell: "",
      listedInAgency: "",
      mandatoryField: "*Camp obligatori",
      privacyPolicy:
        "Les dades que proporciones són tractades per {IAD_ORIGIN} com a responsable del tractament de dades per tal de crear i gestionar el vostre compte. Teniu dret a accedir, rectificar, oposar-te, eliminar, limitar i portar les teves dades. Per saber més sobre el tractament de les teves dades per part de {IAD_ORIGIN} i els teus drets, consulta la <a>política de privacitat.",
    },
    textFieldLabels: {
      year: "Tria un any​",
      area: "En m²",
      select: "Seleccioneu una opció",
      text: "Introduce un valor",
      firstName: "Nom*",
      lastName: "Cognom*",
      name: "",
      email: "Email*: nom@gmail.com",
    },
    options: {
      characteristics: {
        doubleWindows: "Doble vidre​",
        suite: "Suite​",
        terrace: "Terrassa​",
        garden: "Jardí​",
        balcony: "Balcó​",
        basement: "​Soterrani",
        pool: "Piscina​",
        airConditioning: "Aire condicionat​",
        closedGarage: "Garatge tancat​",
        equippedKitchen: "Cuina equipada​",
        elevator: "Ascensor​",
        centralHeating: "Calefacció​",
        goodLightExposure: "Lluminós​",
        seaView: "Vistes a la mar​",
        parking: "Plaça de pàrquing​",

        cityView: "Vista de la ciudad",
        electricBlinds: "Estores eléctricos",
        gym: "Gimnasio",
        pantry: "Despensa",
        alarm: "Alarma",
        fireplace: "Chimenea",
        storage: "Almacenamiento",
        videoSecurity: "Video vigilancia",
        wardrobe: "Ropero",
        fakeCeiling: "Falso techo",
        mezzanine: "Entresuelo",
        furnished: "Amueblado",
        commonPool: "Piscina común",
        securityService: "Vigilante/seguridad",
        office: "Oficina",
        highSecurityDoor: "Puertas blindadas",
        woodenFloor: "Suelo de madera",
        patio: "Patio",
      },
      assetType: {
        apartment: "Pis",
        house: "Casa",
      },
      addType: {
        sell: "Comprar",
        rent: "Alquilar",
      },
      condition: {
        new: "Nou​",
        used: "Segona mà​",
        renovated: "Reformat​",
        inConstruction: "En construcció​",
        forRecovery: "A reformar​",
        almostNew: "Como nuevo​",
        ruin: "Ruína",
      },
      numberOfRooms: {
        0: "0",
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        6: "6",
        7: "7",
        8: "8",
        9: "9",
        10: "10",
        11: "11",
        12: "12",
        13: "13",
      },
      // numberOfDivisions: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
      numberOfParkingSpots: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      numberOfWcs: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      energyEfficiency: ["NC", "G", "F", "E", "D", "C", "B-", "B", "A", "A+"],
      owner: {
        true: "Sí",
        false: "No",
      },
      project: {
        sell: "Vull vendre",
        buy: "Vull comprar",
        other: "Un altre projecte",
      },
      timeframe: {
        ongoing: "La meva propietat ja està a la venda​",
        "3months": "En 3 mesos​",
        "3to6months": "En 6 mesos",
        "6monthsOrMore": "Més de 6 mesos",
        estimate: "Només vull saber el preu​",
      },
      areyou: {
        buy: "Comprador",
        professional: "Professional immobiliari",
        other: "Altre",
      },
      type: {
        buy: "",
        sell: "",
        visit: "",
        professional: "",
      },
      sellProcess: {
        start: "",
        ongoing: "",
        estimate: "",
      },
      alreadyTriedToSell: {
        true: "",
        false: "",
      },
      listedInAgency: {
        true: "",
        false: "",
      },
    },
  },
};

export const AUTO_COMPLETE_LOCATION = {
  en: {
    label: "Enter full address",
    noOptions: "No results",
  },
  pt: {
    label: "Insira a morada exata",
    noOptions: "Sem resultados",
  },
  fr: {
    label: "Renseignez l’adresse exacte du bien",
    noOptions: "Aucun résultat",
  },
  es: {
    label: "Introduce la dirección completa",
    noOptions: "Sin resultados",
  },
  cat: {
    label: "Introdueix l'adreça completa",
    noOptions: "Sense resultats",
  },
};

export const ERROR_PAGE = {
  en: {
    goToHome: "Return to homepage",
    error: {
      title: "An error has occured",
      description:
        "We are sorry for the inconvenience and we are fixing the problem, please get back to us later",
    },
    maxLeadsReached: {
      title: "Maximum number of estimations reached",
      description:
        "Thank you for your interest in our tool, we are still processing your requests, please get back to us shortly :)",
    },
  },
  pt: {
    goToHome: "Voltar à página principal",
    error: {
      title: "Ocorreu um erro",
      description:
        "Pedimos desculpa pela inconveniência e estamos a resolver o problema, por favor volte mais tarde",
    },
    maxLeadsReached: {
      title: "Número máximo de estimativas atingido",
      description:
        "Muito obrigado pelo interesse na nossa ferramenta, mas ainda estamos a processar os seus pedidos anteriores, volte a visitar-nos brevemente :)",
    },
  },
  fr: {
    goToHome: "Retour à la page d'accueil",
    error: {
      title: "Une erreur est survenue",
      description:
        "Nous sommes désolés pour la gêne occasionnée et nous résolvons le problème, veuillez nous revenir plus tard",
    },
    maxLeadsReached: {
      title: "Nombre maximum d'estimations atteint",
      description:
        "Merci de l'intérêt que vous portez à notre outil, nous traitons toujours vos demandes, merci de nous recontacter rapidement :)",
    },
  },
};
