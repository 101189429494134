import React, { Profiler } from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

const ProgressBar = (props) => {
  const { total, step } = props;

  return (
    <ParentBar container justify="space-between">
      {[...Array(total).keys()].map((_, idx) => (
        <Bar item key={idx} idx={idx} step={step}></Bar>
      ))}
    </ParentBar>
  );
};

const ParentBar = styled(Grid)`
  height: 10px;
  margin-top: 20px;
`;

const Bar = styled(Grid)`
  width: 15%;
  border-radius: 5px;
  background-color: ${(props) =>
    props.idx > props.step ? "#ebf4ff" : "#00628c"};
  transition: background-color 500ms ease;
`;

export default ProgressBar;
