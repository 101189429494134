import React from "react";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";

import styled from "styled-components";
import SvgIcon from "@mui/material/SvgIcon";

const FormChipSelectField = (props) => {
  const {
    options,
    labels,
    error,
    helperText,
    value,
    title,
    handleEntryChange,
    id,
    validate,
    mobile,
  } = props;

  const selectIcon = (id, selected = false) => {
    switch (id) {
      case "apartment":
        return (
          <SvgIcon color={selected ? "#FFFFFF" : "#636363"} viewBox="0 0 23 24">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.6799 24L0.526321 23.9988V0H22.6787V23.9975L12.5252 23.9988V20.306H10.6799V24ZM8.83369 18.4607H14.3718V22.1524H20.8325V1.84641H2.37295V22.1524H8.83369V18.4607ZM18.9868 20.306H15.294V18.4607H18.9868V20.306ZM7.91058 20.306H4.21778V18.4607H7.91058V20.306ZM18.9868 16.6143H15.294V14.7679H18.9868V16.6143ZM13.4487 16.6143H9.75587V14.7679H13.4487V16.6143ZM7.91058 16.6143H4.21778V14.7679H7.91058V16.6143ZM18.9868 12.9226H15.294V11.0762H18.9868V12.9226ZM13.4487 12.9226H9.75587V11.0762H13.4487V12.9226ZM7.91058 12.9226H4.21778V11.0762H7.91058V12.9226ZM18.9868 9.22979H15.294V7.3845H18.9868V9.22979ZM13.4487 9.22979H9.75587V7.3845H13.4487V9.22979ZM7.91058 9.22979H4.21778V7.3845H7.91058V9.22979ZM18.9868 5.53809H15.294V3.69169H18.9868V5.53809ZM13.4487 5.53809H9.75587V3.69169H13.4487V5.53809ZM7.91058 5.53809H4.21778V3.69169H7.91058V5.53809Z"
            />
          </SvgIcon>
        );
      case "house":
        return (
          <SvgIcon color={selected ? "#FFFFFF" : "#636363"} viewBox="0 0 27 24">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M23.9302 24H2.59609V12.184L1.31492 13.3389L0 11.9489L13.2632 0L19.0814 5.24198V3.18085H21.0211L21.0211 6.96584H20.9966L26.5263 11.9489L25.2126 13.3389L23.9302 12.184L23.9302 24ZM13.2632 2.5767L4.53589 10.4374V22.1081H21.9905V10.4374L13.2632 2.5767ZM20.0507 20.215H14.2325L14.2325 13.591H20.0507V20.215ZM16.1723 15.4829V18.3219H18.1121V15.4829L16.1723 15.4829ZM12.2939 20.215H6.47567V13.591H12.2939V20.215ZM8.41429 15.4829V18.3219H10.3541V15.4829H8.41429Z"
            />
          </SvgIcon>
        );
      default:
        return <div></div>;
    }
  };

  return (
    <Grid container>
      <Title item xs={12}>
        <Typography
          variant="subtitle1"
          style={{ fontSize: mobile ? "16px" : "18px" }}
        >
          {title}
        </Typography>
      </Title>
      <Grid item xs={12}>
        <Grid container alignItems="center">
          {options.map((opt) => (
            <FormField item key={opt}>
              <Chip
                clickable
                key={opt}
                style={{ fontSize: "16px" }}
                label={labels[opt]}
                // icon={selectIcon(opt, opt === value)}
                color={value === opt ? "primary" : "secondary"}
                onClick={() => handleEntryChange(id, opt, validate)}
              ></Chip>
            </FormField>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Error variant="caption">{error && helperText}</Error>
      </Grid>
    </Grid>
  );
};

const Title = styled(Grid)`
  padding-bottom: 5px;
`;

const FormField = styled(Grid)`
  padding: 5px 15px 5px 0px;
`;

const Error = styled(Typography)`
  color: red;
  height: 14px;
`;

export default FormChipSelectField;
