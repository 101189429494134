import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setLeadField,
  setLeadClientDetail,
  toggleLeadListElement,
  toggleErrorLeadField,
  toggleErrorLeadClientDetail,
  setLanguage,
  setAgentReference,
  setKeyValues,
} from "../../redux/actions";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import styled from "styled-components";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import Typography from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import ProgressBar from "../../components/ProgressBar/ProgressBar";

import Form from "../../components/Form";
import ErrorPage from "../../components/ErrorPage";
import Buttons from "../../components/Buttons";
import Title from "../../components/Title";

import formPT from "../../data/form-pt";
import formES from "../../data/form-es";
import metadataPT from "../../data/metadata-pt";
import metadataES from "../../data/metadata-es";

import { MAIN_SUBTITLES } from "../../data/copy";
import { uploadToAnalytics } from "../../helpers/Analytics";

const App = (props) => {
  const [page, setPage] = useState(0);
  const [mounted, setMount] = useState(null);
  const [setup, setSetup] = useState(null);
  const [formHeight, setFormHeight] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const dispatch = useDispatch();
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));

  const { lang, lead, client, error, realtor, form } = useSelector((state) => state.reducer);
  const subTitle = form ? form[page].subTitle : null;
  const parentHeight = document.getElementById("root").parentElement.offsetHeight;

  useEffect(() => {
    setupApp();

    window.addEventListener("resize", updateFormHeight);

    return () => {
      window.removeEventListener("resize", updateFormHeight);
    };
  }, []);

  useEffect(() => {
    if (!mounted && setup) {
      updateFormHeight();
      setMount(true);
    }
  }, [setup, mounted]);

  const mutationObserverCallback = (mutationsList, observer) => {
    for (const mutation of mutationsList) {
      if (mutation.type === "attributes") {
        if (mutation.attributeName === "language")
          updateLanguage(mutation.target.getAttribute("language"));
        else if (mutation.attributeName === "address") {
          const address = mutation.target.getAttribute("address");
          if (address) dispatch(setLeadField("address", address, true));
        } else if (mutation.attributeName === "latitude") {
          const latitude = mutation.target.getAttribute("latitude");
          if (latitude && parseFloat(latitude))
            dispatch(setLeadField("latitude", parseFloat(latitude)));
        } else if (mutation.attributeName === "longitude") {
          const longitude = mutation.target.getAttribute("longitude");
          if (longitude && parseFloat(longitude))
            dispatch(setLeadField("longitude", parseFloat(longitude)));
        } else if (mutation.attributeName === "agent_reference") {
          const realtor = mutation.target.getAttribute("agent_reference");
          dispatch(setAgentReference(realtor));
        }
      }
    }
    return;
  };

  const setupApp = () => {
    const rootNode = document.getElementById("root");
    const observer = new MutationObserver(mutationObserverCallback);

    const language = rootNode.getAttribute("language");
    updateLanguage(language);

    localStorage.setItem(
      "auth",
      JSON.stringify({
        token:
          process.env.REACT_APP_ORIGIN === "pt"
            ? "GSn39K+ZflrWbV8Y6gYYQO//OHDB7A0z"
            : "GTn39K+ZflrWbV8Y6gYYQO//OHDB7A0z",
      })
    );

    const form = process.env.REACT_APP_ORIGIN === "pt" ? formPT : formES;
    const metadata = process.env.REACT_APP_ORIGIN === "pt" ? metadataPT : metadataES;

    dispatch(setKeyValues({ form, metadata }));

    let realtor;
    if (process.env.REACT_APP_MODE !== "build") {
      const urlParams = new URLSearchParams(window.location.search);
      realtor = urlParams.get("agent_reference");
    } else realtor = rootNode.getAttribute("agent_reference");

    if (realtor) dispatch(setAgentReference(realtor));

    observer.observe(rootNode, { attributes: true });
    uploadToAnalytics(window.dataLayer, {
      step: 0,
      realtor,
      origin: process.env.REACT_APP_ORIGIN,
    });
    setSetup(true);
  };

  const updateLanguage = (language) => {
    if (!language) language = window.navigator.language;

    if (language.includes("pt")) language = "pt";
    else if (language.includes("fr")) language = "fr";
    else if (language.includes("ca")) language = "cat";
    else if (language.includes("es")) language = "es";
    else language = "en";

    if (language && ["pt", "en", "es", "cat", "fr"].includes(language))
      dispatch(setLanguage(language));
  };

  const updateFormHeight = () => {
    const headerHeight = document.getElementById("estimate-header").offsetHeight;
    const footerHeight = document.getElementById("estimate-footer")
      ? document.getElementById("estimate-footer").offsetHeight
      : 0;
    setFormHeight(window.innerHeight - headerHeight - footerHeight);
  };

  // Function that validates the form fields
  const handleEntryChange = (id, userInput, validator) => {
    const { value, error, helperText } = validator(userInput, lang);

    if (id === "characteristics") dispatch(toggleLeadListElement(id, value));
    else if (id === "address") {
      dispatch(setLeadField("address", value?.address));
      dispatch(setLeadField("latitude", value?.latitude));
      dispatch(setLeadField("longitude", value?.longitude));
    } else if (lead.fields[id]) dispatch(setLeadField(id, value));
    else if (client[id]) {
      if (id === "type") {
        dispatch(setLeadClientDetail("sellProcess", ""));
        dispatch(setLeadClientDetail("alreadyTriedToSell", ""));
        dispatch(setLeadClientDetail("listedInAgency", ""));
      }

      dispatch(setLeadClientDetail(id, value));
    }

    if (id !== "characteristics") {
      if (lead.fields[id]) {
        dispatch(toggleErrorLeadField(id, error, helperText));
      } else if (client[id]) {
        dispatch(toggleErrorLeadClientDetail(id, error, helperText));
      }
    }

    const formInputs = document.getElementsByTagName("input");

    // materlada para considerar os autofill dos browsers
    for (const input of formInputs) {
      if (["name", "email"].includes(input.name)) {
        dispatch(setLeadClientDetail(input.name, input.value));
      }
    }

    return error;
  };

  const openLanguageMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageSelect = (lang) => () => {
    setAnchorEl(null);
    dispatch(setLanguage(lang));
  };

  const _setPage = (step) => {
    setPage(step);
    uploadToAnalytics(window.dataLayer, {
      step,
      realtor,
      origin: process.env.REACT_APP_ORIGIN,
    });
  };

  return (
    <Grid
      container
      justify="center"
      style={{
        height: process.env.REACT_APP_MODE === "build" ? "100%" : "auto",
        minHeight: process.env.REACT_APP_MODE === "build" ? "100%" : "auto",
      }}
    >
      {process.env.REACT_APP_MODE !== "build" && (
        <Grid item style={{ position: "absolute", top: 0, right: 20 }}>
          <Button aria-controls="simple-menu" aria-haspopup="true" onClick={openLanguageMenu}>
            {lang === "en"
              ? "Change language"
              : lang === "pt"
              ? "Alterar idioma"
              : "Changer de langue"}
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            // onClose={handleClose}
          >
            {["en", "pt", "fr", "es", "cat"].map((lang) => (
              <MenuItem onClick={handleLanguageSelect(lang)} key={lang}>
                {lang.toUpperCase()}
              </MenuItem>
            ))}
          </Menu>
        </Grid>
      )}
      {setup && (
        <Grid item style={{ width: !mobile && "750px", maxWidth: mobile && "750px" }}>
          <PageContainer
            container
            direction="column"
            justify="space-between"
            height={process.env.REACT_APP_MODE === "build" && parentHeight}
          >
            <Header id="estimate-header" item>
              {!error && !loading && (
                <>
                  <Title mobile={mobile} title={form[page].title} loading={loading} />
                  {page < 6 && <ProgressBar step={page} total={6} />}
                </>
              )}
            </Header>
            <FormWithoutScrollbar xs item $height={formHeight}>
              <Grid container>
                <Grid item xs={12}>
                  {mounted &&
                    (error ? (
                      <ErrorPage error={error} />
                    ) : (
                      <Form
                        page={page}
                        setPage={_setPage}
                        loading={loading}
                        setLoading={setLoading}
                        handleEntryChange={handleEntryChange}
                      ></Form>
                    ))}
                </Grid>
              </Grid>
            </FormWithoutScrollbar>
            {!loading && page < 6 && (
              <Footer id="estimate-footer" item>
                {!error && (
                  <Buttons
                    page={page}
                    mobile={mobile}
                    setPage={_setPage}
                    loading={loading}
                    setLoading={setLoading}
                    handleEntryChange={handleEntryChange}
                  />
                )}
              </Footer>
            )}
          </PageContainer>
        </Grid>
      )}
    </Grid>
  );
};

const PageContainer = styled(Grid)`
  height: ${(props) => (props.height ? `${props.height}px` : "100vh")};
`;

const Header = styled(Grid)`
  margin: 60px 0px 50px;
  width: 100%;
  padding: 0px 20px;
`;

const FormWithoutScrollbar = styled(Grid)`
  height: ${(props) => `${props.$height}px`};
  overflow: scroll;
  width: 100%;

  /* Hide scrollbars */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none;
  }
`;

const Footer = styled(Grid)`
  padding: 16px;
  width: 100%;
`;

export default App;
