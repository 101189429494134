import { createStore, combineReducers } from "redux";

import appReducer from "./reducers";

// /**
//  * Every time a dispatch takes place, a key must be present to identify clone instance
//  * @param {*} reducerFunction reducer function to be clone
//  * @param {*} reducerName clone name identifier
//  */
// function createNamedWrapperReducer(reducerFunction, reducerName) {
//   return (state, action) => {
//     let key = action.payload?.key || action.key;
//     const isInitializationCall = state === undefined;
//     if (key !== reducerName && !isInitializationCall) return state;
//     return reducerFunction(state, action);
//   };
// }

const reducer = combineReducers({
  reducer: appReducer,
});

const store = createStore(reducer);

export default store;
