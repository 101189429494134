import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Grid, Button } from "@material-ui/core";
import { FORM_BUTTONS } from "../../data/copy";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const PageMovementButton = (props) => {
  const { buttonType, handleClick, page, mobile } = props;
  const { lang } = useSelector((state) => state.reducer);
  const copy = FORM_BUTTONS[lang];

  return (
    <>
      {/* {buttonType === "previous" && <Grid item xs={12}></Grid>} */}

      {buttonType === "previous" ? (
        <Grid item key={buttonType}>
          <BackButton
            variant="text"
            onClick={() => handleClick(buttonType)}
            color="primary"
            $mobile={mobile}
          >
            {/* <IconButton style={{ padding: 0 }} color="inherit" fontSize="small"> */}
            <ArrowBackIcon fontSize="small"></ArrowBackIcon>
            {/* </IconButton> */}
            &nbsp;&nbsp;
            {copy[buttonType]}
          </BackButton>
        </Grid>
      ) : (
        <PaddedButton
          key={buttonType}
          item
          xs={page === 0 ? 8 : true}
          page={page}
        >
          <ActionButton
            variant="contained"
            onClick={() => handleClick(buttonType)}
            color="secondary"
            $mobile={mobile}
          >
            {copy[buttonType]}
          </ActionButton>
        </PaddedButton>
      )}
    </>
  );
};

const BackButton = styled(Button)`
  height: 48px;
  box-shadow: none;
  padding: 0;
  font-size: ${(props) => props.$mobile && "16px"};
  :hover {
    box-shadow: none;
  }
`;

const PaddedButton = styled(Grid)`
  padding: ${(props) => (props.page === 0 ? "0px" : "0px 0px 0px 40px")};
`;

const ActionButton = styled(Button)`
  height: 48px;
  border-radius: 30px;
  box-shadow: none;
  font-size: ${(props) => props.$mobile && "16px"};
  :hover {
    box-shadow: none;
  }
`;

export default PageMovementButton;
