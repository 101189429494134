import { createMuiTheme } from "@material-ui/core/styles";
import {
  PALLETE_PRIMARY_COLOR,
  PALLETE_LIGHT_PRIMARY_COLOR,
  PALLETE_DARK_PRIMARY_COLOR,
  PALLETE_SECONDARY_COLOR,
  PALLETE_LIGHT_SECONDARY_COLOR,
  PALLETE_DARK_SECONDARY_COLOR,
  BASE_TEXT_COLOR,
  OPPOSITE_TEXT_COLOR,
  GREYED_OUT,
  DARK_GREY,
} from "./colors";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: PALLETE_PRIMARY_COLOR,
      contrastText: BASE_TEXT_COLOR,
    },
    secondary: {
      main: PALLETE_SECONDARY_COLOR,
      contrastText: BASE_TEXT_COLOR,
    },
  },
  typography: {
    fontFamily: ["'Montserrat'", "sans-serif"].join(","),
    button: {
      textTransform: "none",
      fontWeight: 300,
      fontSize: "1.1375rem",
    },
    h3: {
      fontSize: "1.5rem",
      color: OPPOSITE_TEXT_COLOR,
      fontWeight: 700,
    },
    subtitle1: {
      // textTransform: "uppercase",
      fontWeight: 500,
      color: "black",
      lineHeight: "2rem",
    },
    caption: {
      // fontSize: "1rem",
      color: GREYED_OUT,
      lineHeight: "1rem",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: "18px",
        width: "100%",
        lineHeight: 1,
        height: "56px",
      },
      containedSecondary: {
        "&:hover": {
          backgroundColor: PALLETE_DARK_SECONDARY_COLOR,
        },
      },
      containedPrimary: {
        "&:hover": {
          backgroundColor: PALLETE_DARK_PRIMARY_COLOR,
        },
      },
    },
    PrivateNotchedOutline: {
      root: {
        borderRadius: "1.875rem",
        fontWeight: 300,
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: "0px !important",
      },
    },
    MuiAutocomplete: {
      input: {
        padding: "2px 4px !important",
      },
    },
    MuiInputLabel: {
      outlined: { transform: "translate(14px, 14px) scale(1)" },
    },
    MuiInputBase: {
      root: {
        width: "100%",
        color: "black",
        background: "unset !important",
      },
      input: {
        background: "unset !important",
        boxSizing: "unset !important",
        "&:-webkit-autofill": {
          transitionDelay: "9999s",
          transitionProperty: "background-color, color",
        },
      },
    },
    MuiTextField: {
      root: {
        width: "100%",
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: "12px 14px",
      },
      notchedOutline: {
        borderRadius: "5px",
        borderColor: "#d9d9d9",
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    MuiLinearProgress: {
      root: {
        height: 10,
        borderRadius: 5,
      },
      determinate: {
        backgroundColor: PALLETE_LIGHT_SECONDARY_COLOR,
      },
      bar: {
        borderRadius: 5,
      },
    },
    MuiChip: {
      root: {
        // height: "45px",
        fontSize: "0.9375rem",
        borderRadius: "1.875rem",
      },
      colorPrimary: {
        border: "1px solid #00628c",
        borderRadius: "5px",
        backgroundColor: "PALLETE_LIGHT_PRIMARY_COLOR",
        color: PALLETE_PRIMARY_COLOR,
      },
      colorSecondary: {
        border: "1px solid #d9d9d9",
        borderRadius: "5px",
        backgroundColor: "transparent",
        color: DARK_GREY,
      },
      clickableColorPrimary: {
        width: "100%",
        padding: "18px 8px",
        fontWeight: 500,
        backgroundColor: PALLETE_PRIMARY_COLOR,
        color: PALLETE_LIGHT_PRIMARY_COLOR,
        "&:hover": {
          backgroundColor: "#2384ad",
          color: PALLETE_LIGHT_PRIMARY_COLOR,
        },
        "&:focus": {
          backgroundColor: PALLETE_PRIMARY_COLOR,
          color: PALLETE_LIGHT_PRIMARY_COLOR,
        },
      },
      clickableColorSecondary: {
        width: "100%",
        padding: "18px 8px",
        fontWeight: 500,
        // backgroundColor: PALLETE_LIGHT_PRIMARY_COLOR,
        color: DARK_GREY,
        "&:hover": {
          backgroundColor: PALLETE_PRIMARY_COLOR,
          color: "white",
        },
        "&:focus": {
          backgroundColor: PALLETE_PRIMARY_COLOR,
          color: "white",
        },
      },
    },
    MuiSvgIcon: {
      root: {
        width: "24px",
        height: "24px",
      },
    },
    MuiPopover: {
      root: {
        zIndex: "9999999 !important",
      },
    },
  },
});

export default theme;
