import React from "react";
import { useSelector } from "react-redux";
import { Grid, Typography, Link } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/WarningRounded";
import styled from "styled-components";
import { ERROR_PAGE } from "../../data/copy";

const ErrorPage = (props) => {
  // const { lang, error } = useSelector((state) => state.reducer);
  const { lang } = useSelector((state) => state.reducer);
  const { error } = props;
  const copy = ERROR_PAGE[lang];

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      style={{ padding: "0 20px" }}
    >
      <Grid item>
        <Typography variant="h4">
          {copy[error]?.title || copy.error.title}
        </Typography>
      </Grid>
      <Grid item>
        <Icon color="secondary" />
      </Grid>
      <Grid item>
        <Typography variant="body1">
          {copy[error]?.description || copy.error.description}
        </Typography>
      </Grid>
      <BackToHome color="secondary" href="/">
        {copy.goToHome}
      </BackToHome>
    </Grid>
  );
};

const BackToHome = styled(Link)`
  cursor: pointer;
  margin: 20px;
  border: 1px solid;
  text-decoration: none;
  padding: 15px 30px;
  border-radius: 5px;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const Icon = styled(ErrorIcon)`
  width: 200px;
  height: 200px;
  padding: 10px 0 30px;
`;

export default ErrorPage;
