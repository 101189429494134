const PALLETE_PRIMARY_COLOR = "#00628c";
const PALLETE_LIGHT_PRIMARY_COLOR = "#E0F6FF";
const PALLETE_DARK_PRIMARY_COLOR = "#2384ad";
const PALLETE_SECONDARY_COLOR = "#e87722";
const PALLETE_LIGHT_SECONDARY_COLOR = "#FFF2E9";
const PALLETE_DARK_SECONDARY_COLOR = "#d16a1d";
const BASE_TEXT_COLOR = "#FFFFFF";
const OPPOSITE_TEXT_COLOR = "#000000";
const SUBTITLE_COLOR = "#4E4E4E";
const GREYED_OUT = "#AAAFB4";
const DARK_GREY = "#585758";

export {
  PALLETE_PRIMARY_COLOR,
  PALLETE_LIGHT_PRIMARY_COLOR,
  PALLETE_DARK_PRIMARY_COLOR,
  PALLETE_SECONDARY_COLOR,
  PALLETE_LIGHT_SECONDARY_COLOR,
  PALLETE_DARK_SECONDARY_COLOR,
  BASE_TEXT_COLOR,
  OPPOSITE_TEXT_COLOR,
  SUBTITLE_COLOR,
  GREYED_OUT,
  DARK_GREY,
};
