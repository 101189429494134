import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Grid, TextField, MenuItem, Typography } from "@material-ui/core";
import IncrementDecrementField from "../IncrementDecrementField";
import { FORM_FIELDS } from "../../data/copy";

import SvgIcon from "@mui/material/SvgIcon";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    whiteSpace: "initial",
  },
}));

const DropDownIcon = (props) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.125 9.75L13 17.875L4.875 9.75"
      stroke="#AAAFB4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const FormSelectField = (props) => {
  const { entry, handleEntryChange, $mobile, idx } = props;
  const { lead, client, lang, metadata } = useSelector(
    (state) => state.reducer
  );
  const classes = useStyles();
  const copy = FORM_FIELDS[lang];

  // It will either be a lead field or a client details field
  const object = lead.fields[entry.id] || client[entry.id];

  if (!copy.options[entry.id] || !copy.labels[entry.id]) {
    return;
  }

  return (
    <FormField
      item
      xs={entry.width.xs}
      sm={entry.width.sm}
      id={entry.id}
      idx={idx}
      $mobile={$mobile}
    >
      <Grid container>
        <Title item xs={12}>
          <Typography
            variant="subtitle1"
            style={{ fontSize: $mobile ? "16px" : "18px" }}
          >
            {entry.required
              ? `${copy.labels[entry.id]}*`
              : copy.labels[entry.id]}
          </Typography>
        </Title>
        <Grid item xs={12}>
          {/* {mobile && !metadata[entry.id].some(isNaN) ? (
            <IncrementDecrementField
              entry={entry}
              handleEntryChange={handleEntryChange}
            />
          ) : ( */}
          <TextField
            select
            value={object.value}
            error={object.error}
            helperText={object.helperText}
            label={copy.textFieldLabels.select}
            variant="outlined"
            SelectProps={{
              classes: { root: classes.root },
              IconComponent: DropDownIcon,
            }}
            onChange={(event) =>
              handleEntryChange(entry.id, event.target.value, entry.validate)
            }
          >
            {metadata[entry.id].map((option) => (
              <MenuItem key={option} value={option}>
                {copy.options[entry.id][option] || option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </FormField>
  );
};

const FormField = styled(Grid)`
  padding-left: ${(props) => props.idx % 2 > 0 && !props.$mobile && "5px"};
  padding-right: ${(props) => props.idx % 2 === 0 && !props.$mobile && "5px"};
`;

const Title = styled(Grid)`
  padding-bottom: 5px;
`;

export default FormSelectField;
