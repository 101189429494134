import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  Grid,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import { FORM_FIELDS } from "../../data/copy";

const IncrementDecrementField = (props) => {
  const { entry, handleEntryChange } = props;

  const { lang } = useSelector((state) => state.reducer);
  const copy = FORM_FIELDS[lang];

  const [selected, setSelected] = useState(false);
  const [index, setIndex] = useState(0);

  // Add the initial value to the lead object in redux
  useEffect(() => handleEntryChange(entry.id, index, entry.validate), []);

  const toggleUseInEstimate = () => {
    // If the checkbox is selected and we're unselecting it
    if (selected) {
      handleEntryChange(entry.id, null, entry.validate);
    }
    // If the checkbox is unselected and we're selecting it
    else {
      handleEntryChange(entry.id, index, entry.validate);
    }

    setSelected(!selected);
  };

  const numberOfOptions =
    copy.options[entry.id] instanceof Array
      ? copy.options[entry.id].length
      : Object.keys(copy.options[entry.id]).length;

  const decrement = () => {
    if (index > 0) {
      const error = handleEntryChange(entry.id, index - 1, entry.validate);

      if (!error) {
        setIndex(index - 1);
      }

      setSelected(true);
    }
  };

  const increment = () => {
    if (index < numberOfOptions - 1) {
      const error = handleEntryChange(entry.id, index + 1, entry.validate);

      if (!error) {
        setIndex(index + 1);
      }

      setSelected(true);
    }
  };

  return (
    <Grid container>
      <PaddedItem item xs={12}>
        <IncrementorDecrementor
          container
          alignItems="center"
          justify="space-between"
        >
          <Grid item>
            <StyledButton disabled={index <= 0} onClick={decrement}>
              -
            </StyledButton>
          </Grid>
          <Counter selected={selected} required={entry.required} item>
            {copy.options[entry.id][index]}
          </Counter>
          <Grid item>
            <StyledButton
              disabled={index >= numberOfOptions - 1}
              onClick={increment}
            >
              +
            </StyledButton>
          </Grid>
        </IncrementorDecrementor>
      </PaddedItem>
      {/* !entry.required && (
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={selected}
                  onChange={toggleUseInEstimate}
                />
              }
              label="Use in estimate"
            />
          </FormGroup>
        </Grid>
      ) */}
    </Grid>
  );
};

const StyledButton = styled(Button)`
  background-color: #e0f6ff;
  color: #186e93;
  font-weight: 400;
  font-size: 2.2375rem;
  width: 46px;
  height: 46px;
  min-width: 40px;
  border-radius: 14px;

  &:focus {
    background-color: #e0f6ff;
    color: #186e93;
  }
`;

const Counter = styled(Grid)`
  font-size: 1.1375rem;

  ${(props) => !props.selected && !props.required && "color: #AAAFB4;"}
`;

const IncrementorDecrementor = styled(Grid)`
  width: 100%;
  padding: 8px;
  border: 1px solid #cfcfcf;
  border-radius: 18px;
`;

const PaddedItem = styled(Grid)`
  padding-bottom: 14px;
`;

export default IncrementDecrementField;
